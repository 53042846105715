import React, { useState } from 'react'
import CustomDrawerLayout from '../../../../../components/CustomDrawerLayout/CustomDrawerLayout'
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Option } from 'antd/es/mentions';

function TenantSideBar({ open, onClose }) {
    const [form] = Form.useForm();
    const [prefix, setPrefix] = useState
        ('+251'); // Example prefix state for phone numbers

    const handleCreateUser = (values) => {
        console.log('Creating user:', values);
        onClose(); // Close modal after creating
    };

    const handleUpdateUser = (values) => {
        console.log('Updating user:', values);
        onClose(); // Close modal after updating
    };

    const validateEmailURL = async (rule, value) => {
        return Promise.resolve();
    };

    const prefixSelector = (
        <Select value={prefix} onChange={(value) => setPrefix(value)} style={{ width: 70 }}>
            <Option value="+1">+1</Option>
            <Option value="+44">+44</Option>
            <Option value="+251">+251</Option>
        </Select>
    );

    const rolePermissionsData = {
        items: [
            { id: 1, name: 'Admin' },
            { id: 2, name: 'Editor' },
            { id: 3, name: 'Viewer' },
        ],
    };

    const validatePhoneNumber = async (rule, value) => {
        if (!value || value.length >= 10) {
            return Promise.resolve();
        }
        return Promise.reject('Phone number must be at least 10 digits!');
    };

    return (
        <CustomDrawerLayout
            open={open}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            modalHeader="Create Tenants"
        >
            <Form
                form={form}
                name="dependencies"
                autoComplete="off"
                layout="vertical"
                onFinish={handleCreateUser}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="name"
                            label="Admin Name"
                            rules={[
                                { required: true, message: 'Enter group name!' },
                                { max: 30, message: 'Too long character!' },
                                { min: 5, message: 'Set more than 5 characters!' },
                                {
                                    validator: (rule, value) =>
                                        value && /\d/.test(value)
                                            ? Promise.reject(new Error('Name should not contain numbers!'))
                                            : Promise.resolve(),
                                },
                            ]}
                        >
                            <Input placeholder="Enter group name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="email"
                            label="Admin Email"
                            rules={[{ validator: validateEmailURL }]}
                        >
                            <Input type="email" placeholder="Enter Email" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="phone"
                            label="Phone Number"
                            rules={[{ validator: validatePhoneNumber }]}
                        >
                            <Input
                                addonBefore={prefixSelector} placeholder="Phone Number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="roleId"
                            label="User Type"
                            rules={[{ required: true, message: 'Please choose the type' }]}
                        >
                            <Select placeholder="Please choose the type">
                                {rolePermissionsData.items.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>


                <Form.Item>
                    <div className="flex justify-center w-full gap-2 mt-10">
                        <Button size='large' onClick={() => { form.resetFields(); onClose(); }}>Cancel</Button>
                        <Button htmlType="submit" size='large' type="primary">Create</Button>
                    </div>
                </Form.Item>
            </Form>

        </CustomDrawerLayout>
    )
}

export default TenantSideBar