import React, { useState, useEffect } from 'react';
import { Button, Switch, Table } from 'antd';
import { MdOutlineEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import DeleteConfirmationModal from '../../../../../components/Modal/DeleteConfimationModal/DeleteConfirmationModal';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';


// Mock Data
const mockUserData = {
    items: [
        {
            id: 1,
            name: 'John Doe',
            contactInformation: { phone: '+1234567890' },
            role: { name: 'Admin' },
            deletedAt: null,
        },
        {
            id: 2,
            name: 'Jane Smith',
            contactInformation: { phone: '+9876543210' },
            role: { name: 'Editor' },
            deletedAt: null,
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 1,
            name: 'John Doe',
            contactInformation: { phone: '+1234567890' },
            role: { name: 'Admin' },
            deletedAt: null,
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },

        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        }, {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        }, {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
        {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        }, {
            id: 3,
            name: 'Bob Johnson',
            contactInformation: { phone: '+1122334455' },
            role: { name: 'Admin' },
            deletedAt: '2023-08-11', // Deleted account
        },
    ],
    meta: {
        totalItems: 3,
    },
};

function AdminTable() {
    const [open, setOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [deletedItem, setDeletedItem] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [userCurrentPage, setUserCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');


    const displayData = mockUserData;

    const data = displayData?.items?.map((item, index) => ({
        key: index,
        user: item?.name,
        p_no: item?.contactInformation?.phone,
        // user_type: userTypeButton(item?.role?.name),
        account_status: (
            <span className="text-sm text-gray-900">
                {!item?.deletedAt ? <Switch
                    checkedChildren={< CheckOutlined />}
                    unCheckedChildren={< CloseOutlined />}
                    value={true}
                    className='flex flex-row items-center justify-center'
                    onChange={() => { }}
                />

                    : <Switch
                        checkedChildren={< CheckOutlined />}
                        unCheckedChildren={< CloseOutlined />}
                        value={false}
                        className='flex flex-row items-center justify-center'
                        onChange={() => { }}
                    />}
            </span>
        ),
        action: (
            <div className="flex gap-4 text-white">

                <Button
                    id={`editUserButton${item?.id}`}
                    icon={<FiEdit2 size={16} />}
                    type="primary"
                    className="w-[30px] h-[30px]"
                    onClick={() => {
                        setModalType('edit');
                        setSelectedItem({ key: 'edit', id: item?.id });
                        setOpen(true);
                    }} />

                <Button
                    id={`deleteUserButton${item?.id}`}
                    danger
                    icon={<FiTrash2 size={16} />}
                    type="primary" onClick={() => {
                        setDeleteModal(true);
                        setDeletedItem(item?.id);
                    }}
                />
            </div>
        ),
    }));

    const handleDeleteConfirm = () => {
        const updatedData = displayData.items.filter(item => item.id !== deletedItem);
        // This is where you would update your mock data or handle deleting
        console.log('Deleted user with id:', deletedItem);
        setDeleteModal(false);
        setDeletedItem(null);
    };

    const onPageChange = (page, pageSize) => {
        setUserCurrentPage(page);
        if (pageSize) {
            setPageSize(pageSize);
        }
    };

    return (
        <>
            <Table
                className="overflow-y-scroll w-[90%] "
                columns={[
                    {
                        title: 'User',
                        dataIndex: 'user',
                    },
                    {
                        title: 'Phone Number',
                        dataIndex: 'p_no',
                    },
                    {
                        title: 'User Type',
                        dataIndex: 'user_type',
                    },
                    {
                        title: 'Account Status',
                        dataIndex: 'account_status',
                    },
                    {
                        title: 'Action',
                        dataIndex: 'action',
                    },
                ]}
                dataSource={data}
                pagination={{
                    current: userCurrentPage || 1,
                    total: displayData?.meta?.totalItems,
                    pageSize: pageSize,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                loading={false} // No actual loading here since we are using mock data
                bordered
            />
            <DeleteConfirmationModal
                open={deleteModal}
                onConfirm={handleDeleteConfirm}
                onCancel={() => setDeleteModal(false)}
            />
        </>
    );
};

export default AdminTable;
