import React from 'react'
import PageHeader from '../../Header/PageHeader'
import { Button } from 'antd'
import { LuPlus } from 'react-icons/lu'

function AdminConfigs() {
    return (
        <PageHeader title="Admin Configs" size="small">
            <Button
                size="large"
                type="primary"
                icon={<LuPlus size={18} />}
                onClick={() => { }}
            >
                New Admin Configs
            </Button>
        </PageHeader>
    )
}

export default AdminConfigs