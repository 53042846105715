import React from 'react';
import { theme } from 'antd';
import { ClassNames } from '../../utils/ClassNames';

const BlockWrapper = ({ children, className = '' }) => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <div
            className={ClassNames('p-6', {}, [className])}
            style={{
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
            }}
        >
            {children}
        </div>
    );
};

export default BlockWrapper;
