import React from 'react'
import PageHeader from '../../Header/PageHeader'
import { Button } from 'antd'
import { LuPlus } from 'react-icons/lu'

function ClosedDates() {
    return (
        <>
            <PageHeader title="Closed Date" size="small">
                <Button
                    size="large"
                    type="primary"
                    icon={<LuPlus size={18} />}
                    onClick={() => { }}
                >
                    New Closed Date
                </Button>
            </PageHeader>

        </>
    )
}

export default ClosedDates