import { create } from 'zustand';


const tenderSlice = (set) => ({
    isShowTenderSidebar: false,
    tenderId: null,
    tenderContent: "",

    setIsShowTenderSidebar: (isShow) => {
        set({ isShowTenderSidebar: isShow });
    },
    setTenderId: (id) => {
        set({ tenderId: id });
    },
    setTenderContent: (content) => {
        set({ tenderContent: content });
    },
});

export const useTenderStore = create(tenderSlice);