import { Button, Col, Input, Row, Select, Form, DatePicker, Image, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import CustomDrawerLayout from '../../../../../components/CustomDrawerLayout/CustomDrawerLayout';
import dayjs from 'dayjs';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { TextEditor } from '../../../../../components/TextEditor/TextEditor';
import { useTenderStore } from '../../../../../stores/Server/features/Tender-Management/Tender/tenderStore';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { crudRequest } from '../../../../../utils/crudRequest';
import SunEditor from 'suneditor-react';
import { BACKEND_URL } from '../../../../../utils/constants';

const { Option } = Select;
const { Dragger } = Upload;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];


const fontSizes = [
    "13px",
    "14px",
    "16px",
    "18px",
    "24px",
    "32px",
    "40px",
    "48px",
    "80px",
    "96px",
];

const getSubCategories = async () => {
    const response = await fetch(`${BACKEND_URL}/v1/tender-sub-category`, { method: 'GET' });
    return await response.json();
};
const getRegions = async () => {
    const response = await fetch(`${BACKEND_URL}/v1/regions`, { method: 'GET' });
    return await response.json();
};

const getSources = async () => {
    const response = await fetch(`${BACKEND_URL}/v1/sources`, { method: 'GET' });
    return await response.json();
};
const getCompanies = async () => {
    const response = await fetch(`${BACKEND_URL}/v1/companies`, { method: 'GET' });
    return await response.json();
};

const createOrUpdateTender = async (tenderData, tenderId = null) => {
    const url = tenderId
        ? `${BACKEND_URL}/v1/tender/${tenderId}`
        : `${BACKEND_URL}/v1/tender`;
    const method = tenderId ? 'PATCH' : 'POST';

    const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tenderData),
    });

    if (!response.ok) {
        throw new Error(`Failed to ${tenderId ? 'update' : 'create'} tender`);
    }

    return response.json();
};

export const useDeleteTender = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteTender, {
        onSuccess: () => {
            queryClient.invalidateQueries('tenders');
            message.success("Successfully Deleted");
        },
    });
};
const deleteTender = async (id) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/tender/${id}`,
        method: 'DELETE',
    });
};


export const useSetTender = () => {
    const queryClient = useQueryClient();
    return useMutation(setTender, {
        onSuccess: () => {
            queryClient.invalidateQueries('tenders');
            message.success("Successfully Updated");
        },
    });
};

const setTender = async (id, items) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/tender/${id}`,
        method: 'PATCH',
        data: items,
    });
};
const getTender = async () => {
    const response = await fetch(`${BACKEND_URL}/v1/tender`, { method: 'GET' });
    return await response.json();
};

export const useGetTender = () => {
    return useQuery('tenders', getTender, { keepPreviousData: true });
};


const editorOptions = {
    height: 200,
    buttonList: [
        ["undo", "redo"],
        ["removeFormat"],
        ["bold", "underline", "italic", "fontSize"],
        ["fontColor", "hiliteColor"],
        ["align", "horizontalRule", "list"],
        ["table", "link"],
        ["showBlocks", "codeView"]
    ],
    fontSize: [12, 14, 16, 18, 20, 22, 24],
    colorList: [
        [
            "#828282",
            "#FF5400",
            "#676464",
            "#F1F2F4",
            "#FF9B00",
            "#F00",
            "#fa6e30",
            "#000",
            "rgba(255, 153, 0, 0.1)",
            "#FF6600",
            "#0099FF",
            "#74CC6D",
            "#FF9900",
            "#CCCCCC"
        ]
    ],
    imageUploadUrl: "http://localhost:8080/",
    imageGalleryUrl: "http://localhost:8080/"
};




function TenderSidebar() {
    const editorRef = useRef(null);
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const {
        tenderId,
        setTenderId,
        tenderContent,
        setTenderContent,
        isShowTenderSidebar: isShow,
        setIsShowTenderSidebar: setIsShow
    } = useTenderStore();
    const { isLoading, data: subCategoryData } = useQuery('tender_sub_category', getSubCategories);
    const { isLoading: isRegionLoading, data: regionData } = useQuery('regions', getRegions);
    const { isLoading: isSourceLoading, data: sourceData } = useQuery('sources', getSources);
    const { isLoading: isCompanyLoading, data: companyData } = useQuery('companies', getCompanies);



    const onClose = () => {
        form.resetFields();
        setTenderId(null);
        setIsShow(false);
    };

    const onFinish = async () => {
        const values = form.getFieldValue();
        values.content = tenderContent;
        // values.tendercategoryIds = values.tendercategories;
        console.log(values.content);
        await createOrUpdateTender(values, tenderId)
            .then(() => {
                message.success(`Company ${tenderId ? 'updated' : 'created'} successfully`);
                queryClient.invalidateQueries('companies');
                onClose();
            })
            .catch((error) => message.error(`Error: ${error.message}`));

    }
    useEffect(() => {
        if (tenderId) {
            fetch(`${BACKEND_URL}/v1/tender/${tenderId}`)
                .then(res => res.json())
                .then(data => {
                    form.setFieldsValue({
                        ...data,
                        bidOpenDate: data.bidOpenDate ? dayjs(data.bidOpenDate) : null,
                        bidCloseDate: data.bidCloseDate ? dayjs(data.bidCloseDate) : null,
                        publishedAt: data.publishedAt ? dayjs(data.publishedAt) : null,
                        publishedAt: data.publishedAt ? dayjs(data.publishedAt) : null,
                    });
                    setTenderContent(data.content);
                })
                .catch(error => console.error("Error fetching Company data:", error));
        }
    }, [tenderId, form]);

    return (
        isShow && (
            <CustomDrawerLayout
                open={isShow}
                onClose={onClose}
                modalHeader="Create Tender Post"
                className="h-full "
                width="50%"
                footer={

                    <div className="flex justify-center gap-2 py-[2%]">
                        <Button size='large' onClick={onClose}>Cancel</Button>
                        <Button htmlType="submit" onClick={() => form.submit()} size='large' type="primary">{tenderId ? "Update" : "Post"}</Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Col gutter={16}>
                        <Col>
                            <Form.Item
                                name="title"
                                label="Title"
                                rules={[
                                    { required: true, message: 'Enter title!' }
                                ]}
                            >
                                <Input placeholder="Enter title" />
                            </Form.Item>
                        </Col>
                    </Col>


                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="bidOpenDate"
                                label="Bid Open Date"
                                rules={[
                                    { required: true, message: 'Select bid open date' },
                                ]}
                            >
                                <DatePicker className="w-full" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="bidCloseDate"
                                label="Bid Closed Date"
                                rules={[{ required: true, message: 'Select bid closed date' }]}
                            >

                                <DatePicker className="w-full" />
                            </Form.Item>
                        </Col>
                    </Row>


                    {tenderId ? <Form.Item name="tenderSubCategories" label="Select Tender Category" rules={[{ required: true, message: 'Select Company Sector!' }]}>
                        <Select mode="multiple" placeholder="Select Tender Category" loading={isLoading}>
                            {subCategoryData?.map((subcategory) => (
                                <Option key={subcategory.id} value={subcategory.id}>{subcategory.name}</Option>
                            ))}
                        </Select>
                    </Form.Item> :
                        <Form.Item name="tenderSubCategoryIds" label="Select Tender Category" rules={[{ required: true, message: 'Select Company Sector!' }]}>
                            <Select mode="multiple" placeholder="Select sectors" loading={isLoading}>
                                {subCategoryData?.map((subcategory) => (
                                    <Option key={subcategory.id} value={subcategory.id}>{subcategory.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }

                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[{ required: true, message: 'Select status' }]}
                    >

                        <Switch defaultChecked className="" />
                    </Form.Item>




                    {tenderId ? <Form.Item

                        label={
                            <span className="my-1 font-semibold text-gray-700 text-md">
                                Content
                            </span>
                        }
                        name="content"
                    >
                        <SunEditor
                            ref={editorRef}
                            setContents={tenderContent || ""}
                            setOptions={editorOptions}
                            onChange={(content) => setTenderContent(content)} // Only store content here
                            className="rounded-xl "
                            height="500px"
                        />
                    </Form.Item> : <Form.Item
                        id="content"
                        label={
                            <span className="my-1 font-semibold text-gray-700 text-md">
                                Content
                            </span>
                        }
                        name="content"
                    >
                        <TextEditor />
                    </Form.Item>
                    }



                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="regionId"
                                label="Region"
                                rules={[
                                    { required: true, message: 'Select Region' },
                                ]}
                            >
                                <Select name="regionId" placeholder="Select Region">
                                    {regionData?.map((region) => (
                                        <Option key={region.id} value={region.id}>{region.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="companyId"
                                label="Company"
                                rules={[
                                    { required: true, message: 'Select Company' },
                                ]}
                            >
                                <Select placeholder="Select Company">
                                    {companyData.map((company) =>
                                        <Option key={company.id} value={company.id}>{company.name}</Option>
                                    )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="center" style={{ width: '100%' }}>
                        <Form.Item
                            className="w-full text-xs font-normal"
                            label="Tender Documents"
                        >
                            <Upload.Dragger >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Upload</p>
                                <p className="ant-upload-hint">
                                    or drag and drop it here.
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="sourceId"
                                label="Publisher"
                                rules={[
                                    { required: true, message: 'Select Publisher' },
                                ]}
                            >
                                <Select placeholder="Select Publisher">
                                    {sourceData.map((source) =>
                                        <Option key={source.id} value={source.id}>{source.publisher}</Option>
                                    )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="publishedAt"
                                label="Published Date"
                                rules={[
                                    { required: true, message: 'Select Published Date' },
                                ]}
                            >

                                <DatePicker className="w-full" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="bid_document_price"
                                label="Bid Document Price"
                                rules={[
                                    { required: true, message: 'Bid Bond Document Price' },
                                ]}
                            >
                                <Input placeholder="Bid Document Price" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="bid_bond_price"
                                label="Bid Bond Price"
                                rules={[
                                    { required: true, message: 'Bid Bond Price' },
                                ]}
                            >

                                <Input placeholder="Bid Bond Price" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </CustomDrawerLayout >
        )
    )
}

export default TenderSidebar