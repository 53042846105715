import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import _ from "lodash";



function ProtectedRoute({ children }) {


    if (false) {
        return <Navigate to="/login" />;
    }

    return children;
}

export default ProtectedRoute;
