import React, { useState } from 'react'
import PageHeader from '../../../ClientManagement/Settings/Header/PageHeader'
import { Button, Card, Divider, Spin, Tag } from 'antd'
import { LuPlus } from 'react-icons/lu'
import ActionsButtons from '../../../../components/Buttons/ActionButton/ActionsButtons';
import { useSubCategorySettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore';
import CreateSubCategorySidebar from '../Modal/CreateSubCategorySidebar';
import { ClassNames } from '../../../../utils/ClassNames';
import Meta from 'antd/es/card/Meta';
import ActionButton from '../../../../components/Buttons/ActionButton/ActionButton';
import { HiOutlineTag } from 'react-icons/hi';
import { useGetSubCategory } from '../Modal/CreateSubCategorySidebar';
import { useDeleteSubCategory } from '../Modal/CreateSubCategorySidebar';

function SubCategory() {
    const { subcategoryId, setSubCategoryId, isShowSubCategorySidebar, setIsShowSubCategorySidebar } = useSubCategorySettingsStore();
    const { isFetching, data, refetch } = useGetSubCategory();
    const { mutate: deleteSubCategory, isLoading } = useDeleteSubCategory();



    return (
        <div className='flex flex-col justify-center w-full'>
            <PageHeader
                title="SubCategory" size="small">
                <Button
                    size="large"
                    type="primary"
                    icon={<LuPlus size={18} />}
                    onClick={() => { setIsShowSubCategorySidebar(true) }}
                >
                    Add SubCategory
                </Button>

            </PageHeader>
            <Divider className='' />
            <Spin spinning={isFetching}>
                {data?.map((Subcategory) => (
                    <Card
                        style={{ width: 250 }}
                        hoverable
                        className={ClassNames('relative', 'opacity-70')}
                        onClick={() => { }}
                    >
                        <Meta
                            title={
                                <div className="flex items-center gap-1">
                                    <div className="flex-1  text-s econdary text-lg font-semibold text-pretty">
                                        {Subcategory.name}
                                    </div>
                                    <ActionButton
                                        id={Subcategory.id ?? null}
                                        onEdit={() => {
                                            setSubCategoryId(Subcategory.id);
                                            setIsShowSubCategorySidebar(true);
                                        }}
                                        onDelete={() => {
                                            deleteSubCategory(Subcategory.id);
                                        }}
                                    />
                                </div>
                            }
                            description={

                                <div className="text-base w-full py-[2%] text-gray-600">{Subcategory?.description}</div>
                            }
                        />
                        {/* <div className="flex items-center  overflow-x-auto  scroll">
                            {Subcategory.subCategories.map((subSubCategory) =>
                                <Tag icon={<HiOutlineTag className="text-secondary" />} className="flex items-center gap-1 py-[2px] px-[2px] h-[30px] text-base font-semibold text-secondary"> {subSubCategory.name}</Tag>
                            )}
                        </div> */}


                    </Card>
                ))}
            </Spin>
            <CreateSubCategorySidebar />
        </div>
    )
}

export default SubCategory