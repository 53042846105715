import React from 'react';
import { Modal, Button, Image } from 'antd';
import DeleteImage from "../../../assets/Svg/deleteSvg.svg"

const DeleteConfirmationModal = ({ open, onConfirm, onCancel }) => {
    const deleteModalFooter = (
        <div className="flex flex-col items-center justify-center w-full gap-6 mt-6 md:flex-row">
            <Button
                className="px-8 py-4 text-xs font-bold w-70 md:w-auto"
                onClick={onCancel}
            >
                Cancel
            </Button>
            <Button
                id="confirmDeleteId"
                className="px-8 py-4 text-xs font-bold w-70 md:w-auto"
                type="primary"
                onClick={onConfirm}
            >
                Delete
            </Button>
        </div>
    );

    return (
        <Modal
            open={open}
            width={500}
            okText={'Delete'}
            onOk={onConfirm}
            onCancel={onCancel}
            footer={deleteModalFooter}
        >
            <p className="flex justify-center items-center h-[200px]">
                <img
                    src={DeleteImage}
                    width={300}
                    height={300}
                    alt="Delete Confirmation Image"
                />
            </p>
            <p className="flex items-center justify-center mt-4 text-xl font-extrabold text-gray-950">
                Are you sure you want to delete?
            </p>
        </Modal>
    );
};

export default DeleteConfirmationModal;
