import { Divider, Switch, Tag } from 'antd'
import React, { useEffect } from 'react'
import StatusBadge, { StatusBadgeTheme } from '../../../../components/StatusBadge/StatusBadge'
import { HiOutlineTag } from 'react-icons/hi'
import dayjs from 'dayjs'
import ActionButton from '../../../../components/Buttons/ActionButton/ActionButton'
import { useDeleteTender, useGetTender } from '../Components/Modal/TenderSidebar'
import { useTenderStore } from '../../../../stores/Server/features/Tender-Management/Tender/tenderStore'

function TenderCard({ tender }) {
    const { tenderId, setTenderId, isShowTenderSidebar, setIsShowTenderSidebar } = useTenderStore();
    const { isFetching, refetch } = useGetTender();
    const { mutate: deleteTender, isLoading } = useDeleteTender();

    useEffect(() => {
        if (!isShowTenderSidebar) {
            refetch();
        }
    }, [isShowTenderSidebar]);
    return (
        <div class="max-w-xl rounded-xl overflow-hidden h-auto   shadow-lg border-[1px] justify-start flex flex-col w-full py-4 "      >
            <div class="px-4 py-2 ">
                <div class="font-bold text-lg mb-2 w-[100%] flex flex-row justify-between px-4 items-center">
                    <p className='w-[90%] line-clamp-2 text-secondary'>{tender?.title}</p>
                    {/* <Switch onChange={() => { }} className='' value={tender?.published} /> */}
                    <ActionButton id={tender.id ?? null}
                        onEdit={() => {
                            setTenderId(tender.id);
                            setIsShowTenderSidebar(true);
                        }}
                        onDelete={() => {
                            deleteTender(tender.id);
                        }}

                    />
                </div>
                <div className="flex items-center gap-2 " >
                    <p className="text-secondary text-base font-bold font-['Manrope'] tracking-tight">Status</p>
                    <StatusBadge theme={tender.status ? StatusBadgeTheme.success : StatusBadgeTheme.danger} className="h-5 py-4 " >
                        {tender.status ? 'Open' : 'Closed'}
                    </StatusBadge>
                </div>

                <div
                    className="text-base font-normal text-secondary py-1 max-h-[400px] overflow-hidden relative line-clamp-4"
                    dangerouslySetInnerHTML={{ __html: tender?.content }} // Step 2: Insert raw HTML
                />
                <div className="flex items-end gap-2" >
                    <p className="text-secondary text-base font-bold font-['Manrope'] tracking-tight">Bid-Open Date</p>
                    <p className="text-sm text-secondary ">{dayjs(tender.bidOpenDate).format('dddd, MMMM D, YYYY')}</p>
                </div>
                <div className="flex items-end gap-2" >
                    <p className="text-secondary text-base font-bold font-['Manrope'] tracking-tight">Bid-Closed Date</p>
                    <p className="text-sm text-secondary ">{dayjs(tender.bidCloseDate).format('dddd, MMMM D, YYYY')}</p>
                </div>
                <div className="flex items-end gap-2" >
                    <p className="text-secondary text-base font-bold font-['Manrope'] tracking-tight">Region</p>
                    <p className="text-sm text-secondary ">{tender.region.name}</p>
                </div>
                <div className="flex items-end gap-2" >
                    <p className="text-secondary text-base font-bold font-['Manrope'] tracking-tight">Source</p>
                    <p className="text-sm text-secondary ">{tender.source.publisher}</p>
                </div>
            </div>

            <div class="px-4 py-0 ">
                <div className="flex items-end gap-2" >
                    <p className="text-secondary text-base font-bold font-['Manrope'] tracking-tight">Published On</p>
                    <p className="text-sm text-secondary ">October 22, 2024 12:00 AM</p>
                </div>
            </div>
            <div className='w-full px-10'>
                <Divider className='w-[80%] m-0 my-2' />
            </div>

            <div class="px-6 pt-4 pb-2 flex overflow-x-auto  scroll">
                {tender.tenderSubCategories?.map((tags) =>
                    <Tag icon={<HiOutlineTag className="text-secondary" />} className="flex items-center gap-1 px-[2%] text-base font-semibold text-secondary" > {tags.name}</Tag>
                )}
            </div>
        </div>
    )
}

export default TenderCard