import React, { useEffect } from 'react'
import CustomDrawerLayout from '../../../../components/CustomDrawerLayout/CustomDrawerLayout'
import { Button, Col, Form, Input, message } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLanguageSettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore';
import { crudRequest } from '../../../../utils/crudRequest';
import { BACKEND_URL } from '../../../../utils/constants';



const createLanguage = async (languageData, languageId = null) => {
    const url = languageId ? `${BACKEND_URL}/v1/languages/${languageId}` : `${BACKEND_URL}/v1/languages`;
    const method = languageId ? 'PATCH' : 'POST';

    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(languageData),
    });

    if (!response.ok) {
        throw new Error(`Failed to ${languageId ? 'update' : 'create'} region`);
    }

    return response.json();
};


export const useDeleteLanuage = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteLanguage, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('languages');
            message.success("Successfully Delete");
        },
    });
};
const deleteLanguage = async (id) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/languages/${id}`,
        method: 'DELETE',
        data: { id },
    });
};

export const useSetLanguage = () => {
    const queryClient = useQueryClient();
    return useMutation(setLanguage, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('languages');
            message.success("Successfully Updated");
        },
    });
};


const setLanguage = async (id, items) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/languages/${id}`,
        method: 'PATCH',
        data: { items },
    });
};

const getLanguages = async (data) => {
    try {
        const response = await fetch(`${BACKEND_URL}/v1/languages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await response.json();  // Adjust based on your API response structure
    } catch (error) {
        console.error('Error fetching regions:', error);
        return []; // Return an empty array in case of an error
    }
}
export const useGetLanguage = (data, isKeepData = true, isEnabled = true) => {
    return useQuery(
        data ? ['languages', data] : 'languages',
        () => getLanguages(data),
        {
            keepPreviousData: isKeepData,
            enabled: isEnabled,
        }
    );
};



function CreateLanguageSidebar() {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const {
        languageId,
        setLanguageId,
        isShowLanguageSidebar: isShow,
        setIsShowLanguageSidebar: setIsShow,
    } = useLanguageSettingsStore();

    const { data, isFetching, refetch } = useGetLanguage(
        languageId ? { filter: { id: [languageId] } } : {},
        false,
        !!languageId
    );
    useEffect(() => {
        if (languageId) {
            refetch();
        }
    }, [languageId]);

    const { mutate: setLanguage, isLoading } = useSetLanguage();

    const onClose = () => {
        form.resetFields();
        setLanguageId(null);
        setIsShow(false);
    };

    const onFinish = () => {
        const values = form.getFieldValue();
        createLanguage(values, languageId)
            .then(() => {
                message.success(`Region ${languageId ? 'updated' : 'created'} successfully`);
                queryClient.invalidateQueries('regions'); // Refresh region data
                onClose();
            })
            .catch((error) => {
                console.error('Error saving region:', error);
            });
    }

    useEffect(() => {
        const fetchLanguageData = async () => {
            if (languageId) {
                try {
                    const response = await fetch(`${BACKEND_URL}/v1/languages/${languageId}`);
                    if (!response.ok) {
                        throw new Error(`Failed to fetch region data for ID: ${languageId}`);
                    }
                    const item = await response.json();
                    // Set form values correctly
                    form.setFieldsValue({ name: item.name });
                } catch (error) {
                    console.error("Error fetching region data:", error);
                }
            }
        };

        fetchLanguageData();
    }, [languageId]);

    return (
        isShow && (
            <CustomDrawerLayout
                open={isShow}
                onClose={onClose}
                modalHeader="Create Language"
                className="h-full "
                footer={
                    <div className="flex justify-center gap-2">
                        <Button size='large' onClick={onClose}>Cancel</Button>
                        <Button htmlType="submit" size='large' type="primary" loading={isLoading} onClick={() => form.submit()}>
                            {languageId ? "Update" : "Create"}
                        </Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    autoComplete="off"
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Col gutter={16}>
                        <Col>
                            <Form.Item
                                name="name"
                                label="Language"
                                rules={[
                                    { required: true, message: 'Enter Category name!' }
                                ]}
                            >
                                <Input placeholder="Enter Category" />
                            </Form.Item>
                        </Col>
                    </Col>
                </Form>
            </CustomDrawerLayout>)
    )
}

export default CreateLanguageSidebar