import { Button, Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { ClassNames } from '../../../utils/ClassNames';
import DeletePopOver from './DeletePopOver';


function ActionButton({
    onOpen,
    onEdit,
    onDelete,
    onCancelDelete,
    className = '',
    id,
}) {
    const [open, setOpen] = useState(false);
    const items = [];

    useEffect(() => {
        const onCloseOpen = () => {
            if (open) {
                setOpen(false);
            }
        };

        document.addEventListener('click', onCloseOpen);

        return () => {
            document.removeEventListener('click', onCloseOpen);
        };
    }, [open]);


    if (onOpen) {
        items.push({
            key: '0',
            label: (
                <Button
                    size="large"
                    id={`${id}actionButtonForOpenId`}
                    className="w-full justify-normal"
                    type="text"
                    onClick={(e) => {
                        setOpen(false);
                        onOpen(e);
                    }}
                >
                    Open
                </Button>
            ),
            className: 'p-0 hover:bg-transparent',
        });
    }

    if (onEdit) {
        items.push({
            key: '1',
            label: (
                <Button
                    size="large"
                    id={`${id}actionButtonForEditId`}
                    className="w-full justify-normal"
                    type="text"
                    onClick={(e) => {
                        setOpen(false);
                        onEdit(e);
                    }}
                >
                    Edit
                </Button>
            ),
            className: 'p-0 hover:bg-transparent',
        });
    }

    if (onDelete) {
        items.push({
            key: '2',
            label: (
                <DeletePopOver
                    onCancel={() => { }}
                    onDelete={(e) => {
                        setOpen(false);
                        onDelete(e);
                    }}
                >
                    <Button
                        id="deleteActionButton"
                        size="large"
                        className="w-full justify-normal"
                        type="text"
                    >
                        Delete
                    </Button>
                </DeletePopOver>
            ),
            className: 'p-0 hover:bg-transparent',
        });
    }
    return (
        <Dropdown
            menu={{ items }}
            trigger={['click']}
            open={open}
            placement="bottomRight"
            className={ClassNames(className)}
        >
            <Button
                icon={<HiOutlineDotsVertical size={20} className="text-gray-500" />}
                className="h-7 w-7"
                id="buttonDropDownActionId"
                type="text"
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
            />
        </Dropdown>
    )
}

export default ActionButton