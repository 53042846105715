import React, { useEffect, useState } from 'react';
import CustomDrawerLayout from '../../../../components/CustomDrawerLayout/CustomDrawerLayout';
import { Button, Col, ColorPicker, Form, Input, message, Row, Select, Upload } from 'antd';
import { useCompanySettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { crudRequest } from '../../../../utils/crudRequest';
import TextArea from 'antd/es/input/TextArea';
import { InboxOutlined } from '@ant-design/icons';
import { fileUpload } from '../../../../utils/fileUpload';
import { BACKEND_URL } from '../../../../utils/constants';

const { Option } = Select;
const { Dragger } = Upload;

// Reusable API calls with error handling
const createOrUpdateCompany = async (companyData, companyId = null) => {
    const url = companyId
        ? `${BACKEND_URL}/v1/companies/${companyId}`
        : `${BACKEND_URL}/v1/companies`;
    const method = companyId ? 'PATCH' : 'POST';

    const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(companyData),
    });

    if (!response.ok) {
        throw new Error(`Failed to ${companyId ? 'update' : 'create'} company`);
    }

    return response.json();
};

export const useDeleteCompany = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteCompany, {
        onSuccess: () => {
            queryClient.invalidateQueries('companies');
            message.success("Successfully Deleted");
        },
    });
};
const deleteCompany = async (id) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/companies/${id}`,
        method: 'DELETE',
    });
};

export const useSetCompany = () => {
    const queryClient = useQueryClient();
    return useMutation(setCompany, {
        onSuccess: () => {
            queryClient.invalidateQueries('companies');
            message.success("Successfully Updated");
        },
    });
};

const setCompany = async (id, items) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/companies/${id}`,
        method: 'PATCH',
        data: items,
    });
};

const getCompany = async () => {
    const response = await fetch(`${BACKEND_URL}/v1/companies`, { method: 'GET' });
    return await response.json();
};

export const useGetCompany = () => {
    return useQuery('companies', getCompany, { keepPreviousData: true });
};

// Get SubCategory data
const getSubCategory = async () => {
    const response = await fetch(`${BACKEND_URL}/v1/tender-sub-category`, { method: 'GET' });
    return await response.json();
};

function CreateCompanySidebar() {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [fileListURL, setFileList] = useState();

    const { isLoading, data: subCategoryData } = useQuery('tender_sub_category', getSubCategory);

    const {
        companyId,
        setCompanyId,
        isShowCompanySidebar: isShow,
        setIsShowCompanySidebar: setIsShow
    } = useCompanySettingsStore();

    const onClose = () => {
        form.resetFields();
        setCompanyId(null);
        setIsShow(false);
    };

    const onFinish = async () => {
        const values = form.getFieldsValue();
        values.logo = fileListURL
        // const logoUrl = values.logo;
        // Otherwise, assume it's the URL string
        // console.log("Form Value" + " " + logoUrl);
        await createOrUpdateCompany(values, companyId)
            .then(() => {
                message.success(`Company ${companyId ? 'updated' : 'created'} successfully`);
                queryClient.invalidateQueries('companies');
                onClose();
            })
            .catch((error) => message.error(`Error: ${error.message}`));
    };

    useEffect(() => {
        if (companyId) {
            fetch(`${BACKEND_URL}/v1/companies/${companyId}`)
                .then(res => res.json())
                .then(data => form.setFieldsValue(data))
                .catch(error => console.error("Error fetching Company data:", error));
        }
    }, [companyId, form]);


    const handleUpload = async (options) => {
        const { file, onSuccess } = options;
        const response = await fileUpload(file);

        if (response && response.data.secure_url) {
            setFileList(response.data.secure_url)
            form.setFieldsValue({ logo: response.data.secure_url });
            console.log("Uploaded logo URL:", response.data.secure_url);
            if (onSuccess) onSuccess(response);
        }
    };

    const triggerChange = (newFileList) => {
        setFileList(newFileList);
    };
    const handleChange = ({ fileList: newFileList }) => {
        triggerChange(newFileList);
    };


    return (
        isShow && (
            <CustomDrawerLayout
                open={isShow}
                onClose={onClose}
                modalHeader="Create Company"
                width="50%"
                footer={
                    <div className="flex justify-center gap-2">
                        <Button size="large" onClick={onClose}>Cancel</Button>
                        <Button size="large" type="primary" onClick={() => form.submit()}>{companyId ? "Update" : "Create"}</Button>
                    </div>
                }
            >
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item name="name" label="Company Name" rules={[{ required: true, message: 'Enter Company name!' }]}>
                        <Input placeholder="Enter Company name" />
                    </Form.Item>

                    <Form.Item name="bio" label="Company Bio" rules={[{ required: true, message: 'Enter Company bio!' }]}>
                        <TextArea placeholder="Enter Company bio" autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>

                    <Form.Item name="coverPhoto" label="Cover Color" rules={[{ required: true, message: 'Select cover color!' }]}>
                        <ColorPicker size="small" showText onChange={(color) => form.setFieldsValue({ coverPhoto: color.toHexString() })} />
                    </Form.Item>
                    {/* <Form.Item name="logo" label="Logo" rules={[{ required: true, message: 'Enter Logo URL!' }]}>
                        <ColorPicker size="small" showText onChange={(color) => form.setFieldsValue({ coverPhoto: color.toHexString() })} />
                    </Form.Item> */}

                    <Form.Item name="logo" label="Company Logo" >
                        <Upload.Dragger maxCount={1}
                            id='logo-upload' customRequest={handleUpload} onChange={({ fileListURL }) => form.setFieldsValue({ logo: fileListURL })}>
                            <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                            <p>Upload or drag and drop it here</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <Col>
                        <Form.Item name="sectorsOperating" label="Sectors Operating" rules={[{ required: true, message: 'Select Company Sector!' }]} >
                            <Select mode="multiple" placeholder="Select sectors" loading={isLoading} >
                                {subCategoryData?.map((subcategory) => (
                                    <Option key={subcategory.id} value={subcategory.name}>{subcategory.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Form>
            </CustomDrawerLayout>
        )
    );
}

export default CreateCompanySidebar;
