import React from 'react'

function TenantTable() {
    return (
        <div>
            Client Table
        </div>
    )
}

export default TenantTable