import React, { useEffect, useState } from 'react'
import PageHeader from '../../../ClientManagement/Settings/Header/PageHeader'
import { Button, Collapse, Divider, Form, message, Spin } from 'antd'
import { LuPlus } from 'react-icons/lu'
import ActionsButtons from '../../../../components/Buttons/ActionButton/ActionsButtons'
import CreateSourceSidebar, { useDeleteSource, useGetSource } from '../Modal/CreateSourceSidebar';
import { useSourceSettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import dayjs from 'dayjs'





function Source() {
    const { sourceId, setSourceId, isShowSourceSidebar, setIsShowSourceSidebar } = useSourceSettingsStore();
    const { isFetching, data, refetch } = useGetSource();
    const { mutate: deleteSource, isLoading } = useDeleteSource();
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (!isShowSourceSidebar) {
            refetch();
        }
    }, [isShowSourceSidebar]);

    return (
        <div className='flex flex-col justify-center w-full'>
            <PageHeader
                title="Source" size="small">
                <Button
                    size="large"
                    type="primary"
                    icon={<LuPlus size={18} />}
                    onClick={() => { setIsShowSourceSidebar(true) }}
                >
                    Add Source
                </Button>

            </PageHeader>
            <Divider />
            <Spin spinning={isFetching}>
                <div className='flex flex-col items-center justify-center w-full gap-2'>
                    {
                        data?.map((source) => (


                            <Spin spinning={isLoading} >
                                <Collapse
                                    className='mt-3 min-w-[300px] border border-[1px] border-gray-200'
                                    items={[{
                                        key: source.id,
                                        label: <p className='text-secondary flex-1 text-base font-semibold'> {source.publisher}</p>,
                                        extra: (
                                            <ActionsButtons
                                                onEdit={() => {
                                                    setSourceId(source.id);
                                                    setIsShowSourceSidebar(true);
                                                }}
                                                onDelete={() => {
                                                    deleteSource([source.id]);
                                                }}
                                            />
                                        ),
                                        children: (
                                            <>
                                                <div className="flex  first:mt-0 space-x-1">
                                                    <div className="text-sm text-gray-600 font-medium">Name :</div>
                                                    <div className="text-sm text-secondary font-semibold flex-1">
                                                        {source.type}
                                                    </div>
                                                </div>
                                                <div className="flex first:mt-0 space-x-1">
                                                    <div className="text-sm text-gray-600 font-medium ">Published Date :</div>
                                                    <div className="text-sm text-secondary font-semibold flex-1">
                                                        {dayjs(source.publishedAt).format('dddd, MMMM D, YYYY')}
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    }]}
                                    expandIcon={({ isActive }) =>
                                        !isActive ? (
                                            <IoIosArrowDown size={24} className="text-secondary text-sm" />
                                        ) : (
                                            <IoIosArrowUp size={24} className="text-secondary text-sm" />
                                        )
                                    }
                                />
                            </Spin>

                        ))
                    }
                </div>
            </Spin>


            <CreateSourceSidebar />
        </div>
    )
}

export default Source



{/* <Spin spinning={isLoading} >
                                    <div className='w-[600px]' key={source.id}>
                                        <div className="flex flex-row justify-between items-center p-6 rounded-2xl border border-gray-200 mt-6 gap-2.5">
                                            <div className="flex-1 text-lg font-semibold text-secondary">
                                                {source?.publisher}
                                            </div>

                                            <ActionsButtons
                                                id={source?.id ?? null}
                                                onDelete={() => {
                                                    deleteSource([source.id]);
                                                }}
                                                onEdit={() => {
                                                    console.log(source.id)
                                                    setSourceId(source.id);
                                                    setIsShowSourceSidebar(true);
                                                }}
                                            />
                                        </div>
                                    </div>

                                </Spin> */}