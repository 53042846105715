import { Button, Space } from 'antd';
import React, { useState } from 'react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import DeletePopOver from './DeletePopOver';

function ActionsButtons({ disableEdit = false,
    disableDelete = false,
    loading = false,
    onEdit,
    onDelete,
    id, }) {
    const [visible, setVisible] = useState(false);
    return (
        <Space size={10}>
            {onEdit && (
                <Button
                    className="w-[30px] h-[30px]"
                    icon={<FiEdit2 size={16} />}
                    id={`${id}buttonPopOverActionForOnEditActionId`}
                    type="primary"
                    loading={loading}
                    disabled={disableEdit}
                    onClick={onEdit}
                />
            )}

            {onDelete && (
                <DeletePopOver

                    trigger="click"
                    visible={visible}
                    onDelete={onDelete}
                    disabled={disableDelete}
                    onCancel={() => { setVisible(false); }}
                >
                    <Button
                        className="w-[30px] h-[30px]"
                        danger
                        disabled={disableDelete}
                        loading={loading}
                        id={`${id}buttonPopOverActionForOnDeleteActionId`}
                        icon={<FiTrash2 size={16} />}
                        type="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                </DeletePopOver>
            )}
        </Space>
    )
}

export default ActionsButtons