import { Button, Card, Divider, Spin, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { LuPlus } from 'react-icons/lu'
import PageHeader from '../../../ClientManagement/Settings/Header/PageHeader'
import { useDeleteCompany, useGetCompany } from '../Modal/CreateCompanySidebar';
import { useCompanySettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore';
import CreateCompanySidebar from '../Modal/CreateCompanySidebar';
import ActionButton from '../../../../components/Buttons/ActionButton/ActionButton';
import Meta from 'antd/es/card/Meta';
import { ClassNames } from '../../../../utils/ClassNames';
import { HiOutlineTag } from 'react-icons/hi';

function Company() {
    const { companyId, setCompanyId, isShowCompanySidebar, setIsShowCompanySidebar } = useCompanySettingsStore();
    const { isFetching, data, refetch } = useGetCompany();
    const { mutate: deleteCompany, isLoading } = useDeleteCompany();


    useEffect(() => {
        if (!isShowCompanySidebar) {
            refetch();
        }
    }, [isShowCompanySidebar]);

    return (
        <div className='flex flex-col justify-center w-full'>
            <PageHeader
                title="Company" size="small">
                <Button
                    size="large"
                    type="primary"
                    icon={<LuPlus size={18} />}
                    onClick={() => { setIsShowCompanySidebar(true) }}
                >
                    Add Company
                </Button>
            </PageHeader>
            <Divider className='' />
            <Spin spinning={isFetching}>
                <div className='flex flex-row justify-start w-full gap-5'>
                    {data?.map((company) => (
                        <Card
                            style={{ width: 250 }}
                            hoverable
                            className={ClassNames('relative', 'opacity-70')}
                            cover={
                                <div style={{ backgroundColor: company.coverPhoto ? company.coverPhoto : "#cbd5e1", }} className="w-full  h-[170px]  items-center flex justify-center w-full">

                                    <img
                                        src={company.logo}
                                        className="w-[80px] h-[80px] rounded-full bg-gray-300 border-4 border-white"
                                    />
                                </div>
                            }
                            onClick={() => { }} >

                            <Meta
                                title={
                                    <div className="flex items-center gap-1">
                                        <div className="flex-1 text-lg font-bold text-gray-900 text-pretty">
                                            {company.name}
                                        </div>
                                        <ActionButton
                                            id={company.id ?? null}
                                            onEdit={() => {
                                                setCompanyId(company.id);
                                                setIsShowCompanySidebar(true);
                                            }}
                                            onDelete={() => {
                                                deleteCompany(company.id);
                                            }}
                                        />
                                    </div>
                                }
                                description={
                                    <div className="text-base w-[100%] text-gray-600">{company.bio}</div>
                                }
                            />
                            <div className='flex py-2 flex-row flex-wrap space-y-2'>
                                {company.sectorsOperating.map((sectors) =>
                                    <Tag icon={<HiOutlineTag className="text-secondary" />} className="flex items-center gap-1 py-[2px] px-[2%] text-base font-semibold text-secondary" > {sectors}</Tag>
                                )}
                            </div>
                        </Card>

                    ))
                    }
                </div >
            </Spin>
            <CreateCompanySidebar />
        </div >
    )
}

export default Company