import React from 'react';
import { ConfigProvider } from 'antd';
import { Empty } from 'antd';



const AntdConfigProvider = ({ children }) => {
    return (
        <ConfigProvider
            renderEmpty={customizeRenderEmpty}
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#FBA22D',
                    },
                    Input: {
                        colorPrimary: '#FBA22D',
                    }
                },
                token: {
                    colorPrimary: '#FBA22D',
                    colorSuccess: '#0CAF60',
                    colorError: '#E03137',
                    colorWarning: '#FACC15',
                    borderRadius: 9,
                },
            }}
        >
            {children}
        </ConfigProvider>
    )
}

function customizeRenderEmpty() {
    <div className="flex justify-center">
        <div>
            {''}
            <Empty />
            <p className="text-black">Data Not Found</p>
        </div>
    </div>
}

export default AntdConfigProvider