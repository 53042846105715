import React from 'react';
import { ClassNames } from '../../utils/ClassNames';

export const StatusBadgeTheme = {
    secondary: 'secondary',
    warning: 'warning',
    success: 'success',
    danger: 'danger',
};

const StatusBadge = ({
    theme = StatusBadgeTheme.secondary,
    className = '',
    transparentBg = false,
    children,
}) => {
    const badgeClass = ClassNames(
        'min-h-6 py-1 px-4 flex items-center justify-center rounded-lg font-bold text-sm w-max',
        {
            ['bg-success-second/20']:
                theme === StatusBadgeTheme.success && !transparentBg,
            ['text-success']: theme === StatusBadgeTheme.success,
            ['bg-warning-second/20']:
                theme === StatusBadgeTheme.warning && !transparentBg,
            ['text-warning']: theme === StatusBadgeTheme.warning,
            ['bg-error-second/20']:
                theme === StatusBadgeTheme.danger && !transparentBg,
            ['text-error']: theme === StatusBadgeTheme.danger,
            ['bg-secondary/20']:
                theme === StatusBadgeTheme.secondary && !transparentBg,
            ['text-secondary']: theme === StatusBadgeTheme.secondary,
        },
        [className],
    );

    return <div className={badgeClass}>{children}</div>;
};

export default StatusBadge;
