import React from 'react'

function CustomBreadCrumb({ title, subtitle }) {
    return (
        <div className="inline-flex flex-col items-start justify-start gap-2 py-4 grow shrink basis-0">
            <div className="self-stretch text-gray-900 text-2xl font-bold font-['Manrope'] leading-[31.20px]">
                {title}
            </div>
            <div className="self-stretch text-slate-500 text-sm font-medium font-['Manrope'] leading-snug">
                {subtitle}
            </div>
        </div>
    )
}

export default CustomBreadCrumb