import axios from 'axios';

/**
 * Function to perform a CRUD operation by sending a request to the API
 * @param {Object} params - The request parameters including url, method, and optional data
 * @param {string} params.url - The API endpoint URL
 * @param {string} params.method - The HTTP method (e.g., 'GET', 'POST')
 * @param {Object} [params.data] - The data payload to send with the request
 * @param {Object} [params.headers] - Custom headers for the request
 * @param {Object} [params.params] - Query parameters to append to the URL
 * @returns {Promise<any>} - The response data from the API
 */

export const crudRequest = async ({ url, method, data, headers, params }) => {
    try {
        const config = {
            url,
            method,
            headers,
            params,
        };

        if (data) {
            config.data = data;
        }

        const response = await axios(config);
        return response.data;
    } catch (error) {
        throw error;
    }
};
