import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Components from "./components/Components";
import ProtectedRoute from "./hoc/ProtectedRoute";
import Admins from "./pages/AdminManagement/Admins/Admins";
import Dashboard from "./pages/Dashboard/Dashboard";
import NotFound from "./pages/NotFound/NotFound";
import Tender from "./pages/TenderManagement/Tender/Tender";
import AdminSettings from "./pages/ClientManagement/Settings/AdminSettings";
import ClosedDates from "./pages/ClientManagement/Settings/Pages/ClosedDate/ClosedDates";
import AdminSettingsLayout from "./pages/ClientManagement/Settings/Layout/AdminSettingsLayout";
import AdminConfigs from "./pages/ClientManagement/Settings/Pages/AdminConfigs/AdminConfigs";
import Clients from "./pages/ClientManagement/Client/Clients";
import TenderSettingsLayout from "./pages/TenderManagement/Settings/Layout/TenderSettingsLayout";
import Language from "./pages/TenderManagement/Settings/Pages/Language";
import Region from "./pages/TenderManagement/Settings/Pages/Region";
import Company from "./pages/TenderManagement/Settings/Pages/Company";
import Category from "./pages/TenderManagement/Settings/Pages/Category";
import Source from "./pages/TenderManagement/Settings/Pages/Source";
import TenderSettings from "./pages/TenderManagement/Settings/TenderSettings";
import Login from "./pages/Login/Login";
import SubCategory from "./pages/TenderManagement/Settings/Pages/SubCategory";
import EmailTemplate from "./components/EmailTemplate/EmailTemplate";

function App() {
  return (
    <Routes>

      <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>} >
        <Route index element={<Components />} />
        <Route path="admins" element={<Admins />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="admins/setting" element={<AdminSettingsLayout />}>
          <Route index element={<AdminSettings />} />
          <Route path="closed-dates" element={<ClosedDates />} />
          <Route path="admin-configs" element={<AdminConfigs />} />
        </Route>
        <Route path="tender/setting" element={<TenderSettingsLayout />}>
          <Route index element={<TenderSettings />} />
          <Route path="language" element={<Language />} />
          <Route path="region" element={<Region />} />
          <Route path="source" element={<Source />} />
          <Route path="category" element={<Category />} />
          <Route path="subcategory" element={<SubCategory />} />
          <Route path="company" element={<Company />} />
        </Route>
        <Route path="clients" element={<Clients />} />
        <Route path="tender" element={<Tender />} />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="email" element={<EmailTemplate />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
