import { message } from 'antd';
import axios from 'axios';
import React from 'react'

export const fileUpload = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file-tag', Math.round(Math.random() * 1e9));
        formData.append('file', file);
        formData.append('upload_preset', 'bilih-chereta');

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        const response = await axios.post(
            'https://api.cloudinary.com/v1_1/ramjet-it-solution/image/upload',
            formData,
            config,
        );

        return response;
    } catch (error) {
        message.error({
            message: 'File upload error',
            description: '',
        });
        throw error;
    }
}

