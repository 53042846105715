import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function TenderSettings() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/tender/setting/language");
    })
    return (
        <>

        </>
    )
}

export default TenderSettings