'use client';
import { FC, ReactNode, useEffect, useState } from 'react';
import { ConfigProvider, Menu } from 'antd';
import { CiCalendarDate } from 'react-icons/ci';
import { FiFileText } from 'react-icons/fi';
import { TbLayoutList } from 'react-icons/tb';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ClassNames } from '../../../../utils/ClassNames';
import PageHeader from '../Header/PageHeader';
import BlockWrapper from '../../../../components/BlockWrapper/BlockWrapper';

class NMenuItem {
    constructor(items) {
        this.items = items;
    }

    get onlyItems() {
        return this.items.map((item) => item.item);
    }

    findItem(itemKey) {
        const iComponent = this.items.find((item) => item.item.key === itemKey);
        return iComponent ? iComponent : this.items[0];
    }
}

const AdminSettingsLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentItem, setCurrentItem] = useState('');

    const menuItems = new NMenuItem([
        {
            item: {
                key: 'closed-dates',
                icon: (
                    <CiCalendarDate
                        className={ClassNames('', {
                            'text-[#FBA22D]': currentItem === 'closed-dates',
                            'text-gray-500': currentItem !== 'closed-dates',
                        })}
                    />
                ),
                // Hide the label text
                label: <p className="text-sm font-semibold text-gray-900">Closed Date</p>,
                className: currentItem === 'closed-dates' ? 'px-4' : 'px-1',
            },
            link: '/admins/setting/closed-dates',
        },
        {
            item: {
                key: 'admin-configs',
                icon: (
                    <FiFileText
                        className={ClassNames('', {
                            'text-[#FBA22D]': currentItem === 'admin-configs',
                            'text-gray-500': currentItem !== 'admin-configs',
                        })}
                    />
                ),
                label: (<p className="text-sm font-semibold text-gray-900">Admin Configs</p>),
                className: currentItem === 'admin-configs' ? 'px-4' : 'px-1',
            },
            link: '/admins/setting/admin-configs',
        }
        // More menu items ...
    ]);

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        const lastKey = pathSegments[pathSegments.length - 1];
        const mappedKey = lastKey === 'closed-dates' ? 'closed-dates' : lastKey === 'admin-configs' ? 'admin-configs' : '';
        setCurrentItem(mappedKey);
    }, [location]);

    const onMenuClick = (e) => {
        const key = e.key;
        navigate(menuItems.findItem(key).link);
        const item = menuItems.findItem(key);
        if (item) {
            navigate(item.link); // Navigate to the link of the clicked item
        }
    };

    return (
        <div className="w-auto h-auto pb-6 pl-3 pr-6">
            <PageHeader
                title="Settings"
                description="Settings your Leave here"
            />
            <div className="flex gap-6 mt-8">
                <ConfigProvider
                    theme={{
                        components: {
                            Menu: {
                                itemHeight: 56,
                                itemPaddingInline: 0,
                                itemMarginInline: 0,
                                itemMarginBlock: 16,
                                itemActiveBg: '#F8F8F8',
                                itemHoverBg: 'rgba(248,248,248,0.92)',
                            },
                        },
                    }}
                >
                    <Menu
                        className="w-[300px] rounded-2xl py-2 px-6 h-max text-black"
                        items={menuItems.onlyItems}
                        mode="inline"
                        selectedKeys={[currentItem]}
                        onClick={onMenuClick}
                    />
                </ConfigProvider>
                <BlockWrapper className="flex-1 h-max"><Outlet /></BlockWrapper>
            </div>
        </div>
    );
};

export default AdminSettingsLayout;
