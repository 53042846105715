import React from 'react'
import { ClassNames } from '../../../../utils/ClassNames'

function PageHeader({ title, description, size = "medium", children }) {
    return (
        <div className="flex items-center justify-between">
            <div className="flex-1">
                <h2
                    className={ClassNames('text-gray-900', {
                        'text-2xl': size === 'medium',
                        'text-xl': size === 'small',
                    })}
                >
                    {title}
                </h2>
                {description && (
                    <div className="mt-2 text-sm font-medium text-gray-600">
                        {description}
                    </div>
                )}
            </div>
            <div>{children}</div>
        </div>
    )
}

export default PageHeader