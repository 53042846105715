import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    Col,
    Divider,
    DatePicker,
    Input,
    Form,
    InputNumber,
    Radio,
    Rate,
    Row,
    Select,
    Slider,
    Space,
    Switch,
    Upload,
    Table,
    Tag,
} from 'antd';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ReactQuills from './ReactQuill/ReactQuills';




function Components() {
    const [isActive, setIsActive] = useState(false);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,

        },

        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Tags',
            key: 'tags',
            dataIndex: 'tags',
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'loser') {
                            color = 'volcano';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>Invite {record.name}</a>
                    <a>Delete</a>
                </Space>
            ),
        },
    ]
    const { Option } = Select;

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    }

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    }
    const ToggleButton = (checked) => {
        setIsActive(checked);
    }

    function escapeHTMLContent(htmlContent) {
        return htmlContent
            .replace(/"/g, '\\"')    // Escape double quotes
            .replace(/\//g, '\\/')   // Escape forward slashes

    }

    const originalHTML = `<table>
    <thead>
        <tr>
            <th>
                <div><br>
</div>
            </th>
            <th>
                <div><br>
</div>
            </th>
            <th>
                <div><br>
</div>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
                <div><br>
</div>
            </td>
            <td>
                <div><br>
</div>
            </td>
            <td>
                <div><br>
</div>
            </td>
        </tr>
        <tr>
            <td>
                <div><br>
</div>
            </td>
            <td>
                <div><br>
</div>
            </td>
            <td>
                <div><br>
</div>
            </td>
        </tr>
    </tbody>
</table>
`;
    const escapedContent = escapeHTMLContent(originalHTML);
    console.log("Escaped HTML Content" + escapedContent);

    return (
        <div className='flex flex-col'>
            <h1 className="text-success">
                Whereas recognition of the inherent dignity.
            </h1>
            <h2 className="text-blue">
                Whereas recognition of the inherent dignity.
            </h2>
            <h3>Whereas recognition of the inherent dignity.</h3>
            <h4>Whereas recognition of the inherent dignity.</h4>
            <h5>Whereas recognition of the inherent dignity.</h5>
            <h6 className="text-orange">
                Whereas recognition of the inherent dignity.
            </h6>
            <p className='text-error'>Whereas recognition of the inherent dignity.</p>
            <Divider>
                <h2>Rich text</h2>
            </Divider>
            <Form name='validate_other' {...formItemLayout} onFinish={() => { }} initialValues={{
                'input-number': 3,
                'checkbox-group': ['A', 'B'],
                rate: 3.5,
                'color-picker': null,
            }} style={{ maxWidth: 600 }}>
                <Form.Item label="Plain Text">
                    <span className="ant-form-text">China</span>
                </Form.Item>
                <Form.Item
                    name="select"
                    label="Select"
                    hasFeedback
                    rules={[{ required: true, message: 'Please select your country!' }]}
                >
                    <Select placeholder="Please select a country">
                        <Option value="china">China</Option>
                        <Option value="usa">U.S.A</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="select-multiple"
                    label="Select[multiple]"
                    rules={[
                        {
                            required: true,
                            message: 'Please select your favourite colors!',
                            type: 'array',
                        },
                    ]}

                >
                    <Select mode="multiple" placeholder="Please select favourite colors">
                        <Option value="red">Red</Option>
                        <Option value="green">Green</Option>
                        <Option value="blue">Blue</Option>
                    </Select>
                </Form.Item>



                <Form.Item label="InputNumber">
                    <Form.Item name="input-number" noStyle>
                        <InputNumber min={1} max={10} />
                    </Form.Item>
                    <span className="ant-form-text" style={{ marginLeft: 8 }}>
                        machines
                    </span>
                </Form.Item>

                <Form.Item name="switch" label="Switch" valuePropName="checked" className=''>
                    <Switch onChange={onChange} />


                    <Switch
                        checkedChildren={< CheckOutlined />}
                        unCheckedChildren={< CloseOutlined />}
                        value={isActive}
                        onChange={ToggleButton}
                    />
                </Form.Item>

                <Form.Item name="slider" label="Slider">
                    <Slider
                        marks={{
                            0: 'A',
                            20: 'B',
                            40: 'C',
                            60: 'D',
                            80: 'E',
                            100: 'F',
                        }}
                    />
                </Form.Item>

                <Form.Item name="radio-group" label="Radio" >
                    <Radio.Group>
                        <Radio value="a">item 1</Radio>
                        <Radio value="b">item 2</Radio>
                        <Radio value="c">item 3</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="Dragger">
                    <Form.Item
                        name="dragger"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        noStyle
                    >
                        <Upload.Dragger name="files" action="/upload.do">
                            <p className="flex justify-center text-5xl text-primary">
                                <AiOutlineCloudUpload />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload.
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>


                <Form.Item
                    name="range-picker"
                    label="Range picker"
                    rules={[{ required: true, message: 'date range is required!' }]}
                >
                    <DatePicker.RangePicker />
                </Form.Item>


                <div className='flex flex-col'>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                                type: 'email',
                            },
                        ]}
                    >
                        <Input
                            placeholder="type your email"
                            className="w-full h-10"
                            allowClear
                        />
                    </Form.Item>
                </div>

                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Space>
                        <Button type='primary' htmlType="submit">
                            Submit
                        </Button>
                        <Button htmlType="reset">
                            Reset
                        </Button>
                    </Space>
                </Form.Item>
            </Form>

            <Divider>
                <h2>Tables</h2>
            </Divider>
            <div className="mx-8">
                <Table columns={columns} dataSource={[]} />
            </div>

            <Divider>
                <h2>React Quill</h2>
            </Divider>
            {/* <ReactQuills /> */}
        </div>

    )
}

export default Components