import { Button, Checkbox, Form, Input } from 'antd';
import _ from 'lodash';
import React from 'react'
import { AiFillPauseCircle } from 'react-icons/ai';
import { BsGoogle } from 'react-icons/bs';
import { RiLockPasswordFill } from 'react-icons/ri';
import { useMutation } from 'react-query';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import BilihCheretaLogo from '../../assets/logos/bilih_chereta_text_only.svg';
import Background from "../../assets/images/Background.png"


function Login() {
    const navigate = useNavigate();


    const { mutate, isLoading, isError, error } = useMutation((credentials) => AiFillPauseCircle.post("https://api.ichereta.com/api/auth/login", credentials),
        {
            onSuccess: async (response, variables, context) => {
                const { data } = response;

                navigate("/tenders");


                // console.log("Lodash", _.isEqual(data.result.user.user_type, "ADMIN"));

                // if (_.isEqual(data.result?.user?.user_type, "ADMIN")) {
                //     setToken(data.result.token);
                //     console.log("User", data.result.user.full_name)
                //     setUser(data.result.user);
                //     setAvailableOrganizations(data.result.organizations);
                //     message.success(`Welcome back`);
                //     navigate("/pick-organization")
                // } else {
                //     console.log("Unauthorized", this.credentials);
                //     errorNotification("Unauthorized user!");
                // }
            }
        }
    );
    const handleLogin = (values) => {
        mutate(values);
    }


    return (
        <div className='flex flex-col justify-center h-screen '
            style={{
                backgroundImage: `linear-gradient(
                            rgba(251, 162, 45, 0.3), 
                            rgba(251, 162, 45, 0.3)
                        ), url(${Background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}>
            <div className="py-16 ">
                <div className="flex max-w-sm mx-auto overflow-hidden  rounded-lg shadow-lg lg:max-w-[23%] ">

                    <div className="w-auto w-1/2 h-auto p-8 lg:w-full bg-slate-100">
                        <div className='w-full'>
                            <div className="flex justify-center flexitems-center">
                                <img className='flex-shrink-0 flex-grow-0 md:h-[30px] text-center' src={BilihCheretaLogo} />
                            </div>
                            <div className="pt-4">
                                <p className="text-xl font-normal text-center ">Login</p>
                            </div>
                            <Form
                                name="login-form"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                initialValues={{ remember: true }}
                                onFinish={handleLogin}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label={<p className="text-base font-medium">Email or Phone Number</p>}
                                    name="username"
                                    validateStatus={
                                        isError && error.response.data.errors.email
                                            ? "error"
                                            : ""
                                    }
                                    help={
                                        isError && error.response.data.errors.email
                                            ? error.response.data.errors.email
                                            : ""
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                            type: 'email',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter your email"
                                        className="w-full h-10"
                                        allowClear
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<p className="text-base font-medium">Password</p>}
                                    name="password"
                                    validateStatus={
                                        isError &&
                                            error.response.data.errors.password
                                            ? "error"
                                            : ""
                                    }
                                    help={
                                        isError &&
                                            error.response.data.errors.password
                                            ? error.response.data.errors.password
                                            : ""
                                    }
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password
                                        placeholder="Enter your password"
                                        className="w-full h-10"
                                        prefix={<RiLockPasswordFill />}
                                        allowClear
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Checkbox className="m-1">Remember me</Checkbox>
                                    </Form.Item>
                                    <Button className="float-right p-0 m-0" type="link">
                                        Forgot password
                                    </Button>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        className="py-5 my-4"
                                        type="primary"
                                        loading={isLoading}
                                        disabled={isLoading}
                                        htmlType="submit"
                                        block
                                    >
                                        Login
                                    </Button>
                                </Form.Item>
                            </Form>
                            <p className="mb-5 text-xs font-light text-center">Or login with</p>
                            <div className="flex flex-col items-center justify-center gap-2 sm:flex-row">
                                <Button
                                    size="large"
                                    icon={<BsGoogle />}
                                    className="w-40 p-4 text-sm bg-transparent"
                                    onClick={() => { }}
                                    block
                                >
                                    Google
                                </Button>
                            </div>
                            <br />
                            <div className="text-xs font-thin text-center">
                                © 2024 <NavLink target="_blank" to="https://bilih.digital/" className="font-semibold cursor-pointer text-normal text-primary">Bilih Digital.</NavLink> All-rights reserved.
                                <br />
                                <span className="ml-1 font-semibold cursor-pointer">
                                    Terms of Conditions
                                </span>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Login