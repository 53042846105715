import { create } from 'zustand';


const regionSettingsSlice = (set) => ({
    isShowRegionSidebar: false,
    regionId: null,

    setIsShowRegionSidebar: (isShow) => {
        set({ isShowRegionSidebar: isShow });
    },
    setRegionId: (id) => {
        set({ regionId: id });
    },
});

const languageSettingsSlice = (set) => ({
    isShowLanguageSidebar: false,
    languageId: null,

    setIsShowLanguageSidebar: (isShow) => {
        set({ isShowLanguageSidebar: isShow });
    },
    setLanguageId: (id) => {
        set({ languageId: id });
    },
});



const sourceSettingsSlice = (set) => ({
    isShowSourceSidebar: false,
    sourceId: null,

    setIsShowSourceSidebar: (isShow) => {
        set({ isShowSourceSidebar: isShow });
    },
    setSourceId: (id) => {
        set({ sourceId: id });
    },
});

const companySettingsSlice = (set) => ({
    isShowCompanySidebar: false,
    companyId: null,

    setIsShowCompanySidebar: (isShow) => {
        set({ isShowCompanySidebar: isShow });
    },
    setCompanyId: (id) => {
        set({ companyId: id });
    },
});

const categorySettingsSlice = (set) => ({
    isShowCategorySidebar: false,
    categoryId: null,

    setIsShowCategorySidebar: (isShow) => {
        set({ isShowCategorySidebar: isShow });
    },
    setCategoryId: (id) => {
        set({ categoryId: id });
    },
});

const subCategorySettingsSlice = (set) => ({
    isShowSubCategorySidebar: false,
    subCategoryId: null,

    setIsShowSubCategorySidebar: (isShow) => {
        set({ isShowSubCategorySidebar: isShow });
    },
    setSubCategoryId: (id) => {
        set({ subCategoryId: id });
    },
});



export const useTenderSettingsStore = create(regionSettingsSlice);

export const useLanguageSettingsStore = create(languageSettingsSlice);

export const useSourceSettingsStore = create(sourceSettingsSlice);

export const useCategorySettingsStore = create(categorySettingsSlice);

export const useSubCategorySettingsStore = create(subCategorySettingsSlice);

export const useCompanySettingsStore = create(companySettingsSlice);


