import { Button, Form, Input, message } from 'antd';
import React, { useEffect } from 'react';
import CustomDrawerLayout from '../../../../components/CustomDrawerLayout/CustomDrawerLayout';
import { useMutation, useQueryClient } from "react-query";
import { successNotification } from '../../../../utils/notifications';
import { useRegionSettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/regionSettingsStore';
import { useGetRegion } from '../Pages/Region';
import { useSetRegion } from '../Components/ListingCard/ListingCard';
import { BACKEND_URL } from '../../../../utils/constants';

const saveRegion = async (regionData, regionId = null) => {
    const url = regionId ? `${BACKEND_URL}/v1/regions/${regionId}` : `${BACKEND_URL}/v1/regions`;
    const method = regionId ? 'PATCH' : 'POST';

    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(regionData),
    });

    if (!response.ok) {
        throw new Error(`Failed to ${regionId ? 'update' : 'create'} region`);
    }

    return response.json();
};

function CreateRegionSidebar() {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const {
        regionId,
        setRegionId,
        isShowRegionSidebar: isShow,
        setIsShowRegionSidebar: setIsShow,
    } = useRegionSettingsStore();

    const { data, isFetching, refetch } = useGetRegion(
        regionId ? { filter: { id: [regionId] } } : {},
        false,
        !!regionId
    );

    useEffect(() => {
        if (regionId) {
            refetch();
        }
    }, [regionId]);

    const { mutate: setRegion, isLoading } = useSetRegion();

    const onClose = () => {
        form.resetFields();
        setRegionId(null);
        setIsShow(false);
    };

    const onFinish = () => {
        const values = form.getFieldValue();
        saveRegion(values, regionId)
            .then(() => {
                message.success(`Region ${regionId ? 'updated' : 'created'} successfully`);
                queryClient.invalidateQueries('regions'); // Refresh region data
                onClose();
            })
            .catch((error) => {
                console.error('Error saving region:', error);
            });
    }


    useEffect(() => {
        const fetchRegionData = async () => {
            if (regionId) {
                try {
                    const response = await fetch(`${BACKEND_URL}/v1/regions/${regionId}`);
                    if (!response.ok) {
                        throw new Error(`Failed to fetch region data for ID: ${regionId}`);
                    }
                    const item = await response.json();
                    // Set form values correctly
                    form.setFieldsValue({ name: item.name });
                } catch (error) {
                    console.error("Error fetching region data:", error);
                }
            }
        };

        fetchRegionData();
    }, [regionId]);


    return (
        isShow && (
            <CustomDrawerLayout
                open={isShow}
                onClose={onClose}
                modalHeader={regionId ? "Edit Region" : "Create Region"}
                footer={
                    <div className="flex justify-center gap-2">
                        <Button size="large" onClick={onClose}>Cancel</Button>
                        <Button htmlType="submit" loading={isLoading} size="large" type="primary" onClick={() => form.submit()}>
                            {regionId ? "Update" : "Create"}
                        </Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="name"
                        label="Region"
                        rules={[{ required: true, message: 'Please enter the region name!' }]}
                    >
                        <Input placeholder="Enter Region Name" />
                    </Form.Item>
                </Form>
            </CustomDrawerLayout>
        )
    );
}

export default CreateRegionSidebar;
