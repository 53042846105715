import React from 'react'
import CustomButton from '../../../components/CustomButton/CustomButton';
import CustomBreadCrumb from '../../../components/CustomBreadCrumb/CustomBreadCrumb';
import { FaPlus } from 'react-icons/fa6';
import { Row } from 'antd';
import TenderCard from './Page/TenderCard';
import TenderPost, { useDeleteTender, useGetTender } from './Components/Modal/TenderSidebar';
import { useTenderStore } from '../../../stores/Server/features/Tender-Management/Tender/tenderStore';




function Tender() {

    const { tenderId, setIsShowTenderSidebar } = useTenderStore();
    const { isFetching, data, refetch } = useGetTender();
    const { mutate: deleteTender, isLoading } = useDeleteTender();


    return (
        <div className='w-auto h-auto px-5 py-[1%] pb-[10%] bg-white rounded-lg'>
            <div className='flex flex-wrap items-center justify-between'>
                <CustomBreadCrumb title="Tender Management" subtitle="Manage tender" />
                <CustomButton
                    title="Post tender"
                    id="createAdminButton"
                    onClick={() => { setIsShowTenderSidebar(true) }} // Open sidebar on button click
                    className="bg-blue-600 hover:bg-blue-700"
                    icon={<FaPlus className="mr-2" />}
                />

                {/* Passs open state and onClose handler to UserSideBar */}
                <TenderPost />
            </div>
            <div className="flex justify-center w-full h-auto">
                {/* <TenderSearchBar /> */}
                <Row gutter={16} className='justify-center gap-6 px-4'>
                    {data?.items.map((tender) => (
                        <TenderCard tender={tender} />
                    ))}
                </Row>


            </div>
        </div>
    );
}

export default Tender