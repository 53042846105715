import { Button, Col, Popover, Row } from 'antd';
import React from 'react'

function DeletePopOver({ titleText = 'Are you sure you want to delete?',
    onCancel,
    onDelete,
    children,
    disabled = false, }) {

    const buttonClass = 'text-xs font-bold w-full h-[29px] min-w-[125px]';
    if (disabled) {
        return children;
    }

    return (
        <Popover
            trigger="hover"
            placement="bottomRight"
            title={
                <div className="text-base font-bold text-gray-900">{titleText}</div>
            }
            content={
                <div className="pt-4">
                    <Row gutter={24}>

                        {onDelete && (
                            <Col span={12}>
                                <Button
                                    size="small"
                                    className={buttonClass}
                                    type="primary"
                                    danger
                                    id={`buttonPopOverActionDeleteFor${titleText}Id`}
                                    onClick={onDelete}
                                >
                                    Delete
                                </Button>
                            </Col>
                        )}
                        {onCancel && (
                            <Col span={12}>
                                <Button
                                    id={`buttonPopOverActionFor${titleText}Id`}
                                    size="small"
                                    type=''
                                    className={buttonClass}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        )}
                    </Row>
                </div>
            }
        >
            {children}
        </Popover>
    );

}

export default DeletePopOver