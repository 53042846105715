import React, { useEffect, useState } from 'react'
import PageHeader from '../../../ClientManagement/Settings/Header/PageHeader'
import { Button, Divider, List, Spin } from 'antd'
import { LuPlus } from 'react-icons/lu'
import ActionsButtons from '../../../../components/Buttons/ActionButton/ActionsButtons';
import api from '../../../../config/api';
import { useQuery } from 'react-query';
import ListingCard from '../Components/ListingCard/ListingCard';
import { crudRequest } from '../../../../utils/crudRequest';
import { BACKEND_URL } from '../../../../utils/constants';
import { useRegionSettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/regionSettingsStore';
import CreateRegionSidebar from '../Modal/CreateRegionSidebar';


const getRegions = async (data) => {
    try {
        const response = await fetch(`${BACKEND_URL}/v1/regions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await response.json();  // Adjust based on your API response structure
    } catch (error) {
        console.error('Error fetching regions:', error);
        return []; // Return an empty array in case of an error
    }
}
export const useGetRegion = (data, isKeepData = true, isEnabled = true) => {
    return useQuery(
        data ? ['regions', data] : 'regions',
        () => getRegions(data),
        {
            keepPreviousData: isKeepData,
            enabled: isEnabled,
        }
    );
};

function Region() {
    const { isShowRegionSidebar, setIsShowRegionSidebar } = useRegionSettingsStore();

    const { isFetching, data, refetch } = useGetRegion();

    useEffect(() => {
        if (!isShowRegionSidebar) {
            refetch();
        }
    }, [isShowRegionSidebar]);

    return (
        <div className='flex flex-col justify-center w-full'>
            <PageHeader title="Region" size="small">
                <Button
                    size="large"
                    type="primary"
                    icon={<LuPlus size={18} />}
                    onClick={() => { setIsShowRegionSidebar(true) }}
                >
                    Add Region
                </Button>
            </PageHeader>
            <Divider className='' />
            <Spin spinning={isFetching}>
                <div className='flex flex-col items-center justify-center w-full gap-2'>
                    {
                        data?.map((region) => (
                            <ListingCard key={region.id} item={region} />
                        ))
                    }
                </div>
            </Spin>
            <CreateRegionSidebar />
        </div>
    )
}

export default Region