import React, { useRef, useCallback } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import debounce from "lodash.debounce";
import { Button } from "antd";
import { useTenderStore } from "../../stores/Server/features/Tender-Management/Tender/tenderStore";

const editorOptions = {
    height: 200,
    buttonList: [
        ["undo", "redo"],
        ["removeFormat"],
        ["bold", "underline", "italic", "fontSize"],
        ["fontColor", "hiliteColor"],
        ["align", "horizontalRule", "list"],
        ["table", "link"],
        ["showBlocks", "codeView"]
    ],
    fontSize: [12, 14, 16, 18, 20, 22, 24],
    colorList: [
        [
            "#828282",
            "#FF5400",
            "#676464",
            "#F1F2F4",
            "#FF9B00",
            "#F00",
            "#fa6e30",
            "#000",
            "rgba(255, 153, 0, 0.1)",
            "#FF6600",
            "#0099FF",
            "#74CC6D",
            "#FF9900",
            "#CCCCCC"
        ]
    ],
    imageUploadUrl: "http://localhost:8080/",
    imageGalleryUrl: "http://localhost:8080/"
};

// Function to apply styles to <th> elements
const styleTableHeaders = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    const headers = tempDiv.querySelectorAll("th");
    headers.forEach(header => {
        header.style.backgroundColor = "#FEFCEA";
        header.style.color = "#012d4c";
        header.style.fontWeight = "bold";
        header.style.padding = "8px";
        header.style.textAlign = "center";
    });

    // Add border styles to all table elements
    const tables = tempDiv.querySelectorAll("table");
    tables.forEach(table => {
        table.style.borderCollapse = "collapse";
        table.style.width = "100%";
        table.style.border = "1px solid #ddd";
    });

    const cells = tempDiv.querySelectorAll("th, td");
    cells.forEach(cell => {
        cell.style.border = "1px solid #ddd";
        cell.style.padding = "8px";
    });

    return tempDiv.innerHTML;
};

const highlightWords = (content, wordColorMap) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    Object.entries(wordColorMap).forEach(([word, color]) => {
        const regex = new RegExp(`\\b${word}\\b`, "gi");
        tempDiv.innerHTML = tempDiv.innerHTML.replace(
            regex,
            `<span style="background-color: #FDD096; font:semibold;">${word}</span>`
        );
    });

    return tempDiv.innerHTML;
};


const addWatermark = (content) => {
    const watermarkHTML = `
        <div style="position: absolute; transform: rotate(-30deg); top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 3rem; opacity: 0.4; white-space: nowrap; user-select: none; pointer-events: none; color: #FBA22D; font-weight: 500;" class="">Bilih Chereta</div>`;

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    // Append the watermark to the main content
    tempDiv.innerHTML += watermarkHTML;

    return tempDiv.innerHTML;
};


export const TextEditor = () => {
    const editorRef = useRef(null);
    const previousContent = useRef("");
    const { tenderContent, setTenderContent } = useTenderStore();

    // Debounced function to style content
    const updateContentWithStyles = useCallback(
        debounce((content) => {
            let styledContent = styleTableHeaders(content);

            const wordColorMap = {
                "tin": "#FBA22D",
                "CPO": "#FBA22D",
                "VAT": "#FBA22D",
                "Bank Guarantee": "#FBA22D"
            };

            styledContent = highlightWords(styledContent, wordColorMap);
            styledContent = addWatermark(styledContent);


            previousContent.current = styledContent;
            editorRef.current.editor.setContents(styledContent);
        }, 300),
        []
    );

    const handleConversionClick = () => {
        updateContentWithStyles(tenderContent);
    };

    const onContentChange = (content) => {
        setTenderContent(content); // Store the content without processing it
    };

    return (
        <div className="flex flex-col items-center space-y-4">
            <SunEditor
                ref={editorRef}
                setOptions={editorOptions}
                onChange={onContentChange} // Only store content here
                className="rounded-xl"
                height="500px"
            />
            <div className="flex flex-row w-[80%] gap-2">
                <Button className="" size="large" block onClick={handleConversionClick}>
                    Conversion
                </Button>
            </div>
        </div>
    );
};
