import { Button, Col, Form, Input, message } from 'antd';
import React, { useEffect } from 'react'
import CustomDrawerLayout from '../../../../components/CustomDrawerLayout/CustomDrawerLayout';
import { useCategorySettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { crudRequest } from '../../../../utils/crudRequest';
import { BACKEND_URL } from '../../../../utils/constants';


const createCategory = async (categoryData, categoryId = null) => {
    const url = categoryId ? `${BACKEND_URL}/v1/tender-category/${categoryId}` : `${BACKEND_URL}/v1/tender-category`;
    const method = categoryId ? 'PUT' : 'POST';

    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(categoryData),
    });

    if (!response.ok) {
        throw new Error(`Failed to ${categoryId ? 'update' : 'create'} Category`);
    }

    return response.json();
};

export const useDeleteCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteCategory, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('tender-category');
            message.success("Successfully Delete");
        },
    });
};
const deleteCategory = async (id) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/tender-category/${id}`,
        method: 'DELETE',
        data: { id },
    });
};

export const useSetCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(setCategory, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('tender-category');
            message.success("Successfully Updated");
        },
    });
};

const setCategory = async (id, items) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/tender-category/${id}`,
        method: 'PUT',
        data: { items },
    });
};

const getCategory = async (data) => {
    try {
        const response = await fetch(`${BACKEND_URL}/v1/tender-category`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await response.json();  // Adjust based on your API response structure
    } catch (error) {
        console.error('Error fetching Tender Category:', error);
        return []; // Return an empty array in case of an error
    }
}
export const useGetCategory = (data, isKeepData = true, isEnabled = true) => {
    return useQuery(
        data ? ['tender-category', data] : 'tender-category',
        () => getCategory(data),
        {
            keepPreviousData: isKeepData,
            enabled: isEnabled,
        }
    );
};



function CreateCategorySidebar() {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const {
        categoryId,
        setCategoryId,
        isShowCategorySidebar: isShow,
        setIsShowCategorySidebar: setIsShow,
    } = useCategorySettingsStore();

    const { data, isFetching, refetch } = useGetCategory(
        categoryId ? { filter: { id: [categoryId] } } : {},
        false,
        !!categoryId
    );
    const { mutate: setCategory, isLoading } = useSetCategory();


    const onFinish = () => {
        const values = form.getFieldValue();
        createCategory(values, categoryId)
            .then(() => {
                message.success(`Category ${categoryId ? 'updated' : 'created'} successfully`);
                queryClient.invalidateQueries('tender-category');
                onClose();
            })
            .catch((error) => {
                console.error('Error saving Category:', error);
            });
    }
    const onClose = () => {
        form.resetFields();
        setCategoryId(null);
        setIsShow(false);
    };

    useEffect(() => {
        if (categoryId) {
            refetch();
        }
    }, [categoryId]);

    useEffect(() => {
        const fetchCategoryData = async () => {
            if (categoryId) {
                try {
                    const response = await fetch(`${BACKEND_URL}/v1/tender-category/${categoryId}`);
                    if (!response.ok) {
                        throw new Error(`Failed to fetch category data for ID: ${categoryId}`);
                    }
                    const item = await response.json();
                    // Set form values correctly
                    form.setFieldsValue({ name: item.name });
                    form.setFieldsValue({ description: item.description });
                } catch (error) {
                    console.error("Error fetching category data:", error);
                }
            }
        };

        fetchCategoryData();
    }, [categoryId]);

    return (
        isShow && (
            <CustomDrawerLayout
                open={isShow}
                onClose={onClose}
                modalHeader="Create Category"
                className="h-full "
                footer={
                    <div className="flex justify-center gap-2">
                        <Button size='large' onClick={onClose}>Cancel</Button>
                        <Button htmlType="submit" size='large' type="primary" onClick={() => form.submit()}>{categoryId ? "Update" : "Create"}</Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    autoComplete="off"
                    onFinish={onFinish}
                    layout="vertical"
                >

                    <Form.Item
                        name="name"
                        label="Category Name"
                        rules={[
                            { required: true, message: 'Enter Category name!' }
                        ]}
                    >
                        <Input placeholder="Enter Category" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Category Description"
                        rules={[
                            { required: true, message: 'Enter Category Description!' }
                        ]}
                    >
                        <Input placeholder="Enter Category description" />
                    </Form.Item>
                </Form>
            </CustomDrawerLayout>
        )
    )
}

export default CreateCategorySidebar