import React, { useState } from 'react'
import CustomBreadCrumb from '../../../components/CustomBreadCrumb/CustomBreadCrumb'
import CustomButton from '../../../components/CustomButton/CustomButton'
import { FaPlus } from 'react-icons/fa6'
import TenantSideBar from './Components/Modal/TenantSideBar';
import TenantSearch from './Components/Search/TenantSearch';
import TenantTable from './Components/Table/TenantTable';

function Clients() {
    const [open, setModal] = useState(false); // State to manage sidebar visibility
    const showDrawer = () => {
        console.log("Drawer", true);
        setModal(true); // Open the sidebar
    };

    const closeDrawer = () => {
        setModal(false); // Close the sidebar
    };
    return (
        <div className='w-auto h-auto p-4 bg-white rounded-lg'>
            <div className='flex flex-wrap items-center justify-between'>

                <CustomBreadCrumb title="Clients" subtitle="Manage your Clients" />
                <CustomButton
                    title="Create Client"
                    id="createTenantButton"
                    onClick={showDrawer} // Open sidebar on button click
                    className="bg-blue-600 hover:bg-blue-700"
                    icon={<FaPlus className="mr-2" />}
                />
                {open && (
                    <TenantSideBar onClose={closeDrawer} open={open} />
                )}
            </div>
            <div className="w-full h-auto">
                <TenantSearch />
                <TenantTable />
            </div>
        </div>
    )
}

export default Clients