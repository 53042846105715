import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ClassNames } from '../../../../utils/ClassNames';
import BlockWrapper from '../../../../components/BlockWrapper/BlockWrapper';
import { ConfigProvider, Menu } from 'antd';
import PageHeader from '../../../ClientManagement/Settings/Header/PageHeader';
import { GoOrganization } from 'react-icons/go';
import { BiCategory } from 'react-icons/bi';
import { MdOutlineLocationOn } from 'react-icons/md';
import { FaGlobe, FaRegCopyright } from 'react-icons/fa6';


class NMenuItem {
    constructor(items) {
        this.items = items;
    }

    get onlyItems() {
        return this.items.map((item) => item.item);
    }

    findItem(itemKey) {
        const iComponent = this.items.find((item) => item.item.key === itemKey);
        return iComponent ? iComponent : this.items[0];
    }
}

function TenderSettingsLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentItem, setCurrentItem] = useState('');
    const menuItems = new NMenuItem([
        {
            item: {
                key: 'language',
                icon:
                    <FaGlobe
                        className={ClassNames('', {
                            'text-[#FBA22D]': currentItem === 'language',
                            'text-gray-500': currentItem !== 'language',
                        })}
                    />
                ,
                // Hide the label text
                label: <p className="text-sm font-semibold text-gray-900">Laguage</p>,
                className: currentItem === 'language' ? 'px-4' : 'px-1',
            },
            link: '/tender/setting/language',
        },
        {
            item: {
                key: 'region',
                icon:
                    <MdOutlineLocationOn
                        className={ClassNames('', {
                            'text-[#FBA22D]': currentItem === 'region',
                            'text-gray-500': currentItem !== 'region',
                        })}
                    />
                ,
                label: (<p className="text-sm font-semibold text-gray-900">Region</p>),
                className: currentItem === 'region' ? 'px-4' : 'px-1',
            },
            link: '/tender/setting/region',
        },
        {
            item: {
                key: 'source',
                icon:
                    <FaRegCopyright
                        className={ClassNames('', {
                            'text-[#FBA22D]': currentItem === 'source',
                            'text-gray-500': currentItem !== 'source',
                        })}
                    />
                ,
                label: (<p className="text-sm font-semibold text-gray-900">Source</p>),
                className: currentItem === 'source' ? 'px-4' : 'px-1',
            },
            link: '/tender/setting/source',
        },
        {
            item: {
                key: 'category',
                icon:
                    <BiCategory
                        className={ClassNames('', {
                            'text-[#FBA22D]': currentItem === 'category',
                            'text-gray-500': currentItem !== 'category',
                        })}
                    />
                ,
                label: (<p className="text-sm font-semibold text-gray-900">Category</p>),
                className: currentItem === 'category' ? 'px-4' : 'px-1',
            },
            link: '/tender/setting/category',
        },
        {
            item: {
                key: 'subcategory',
                icon:
                    <BiCategory
                        className={ClassNames('', {
                            'text-[#FBA22D]': currentItem === 'subcategory',
                            'text-gray-500': currentItem !== 'subcategory',
                        })}
                    />
                ,
                label: (<p className="text-sm font-semibold text-gray-900">Sub-Category</p>),
                className: currentItem === 'subcategory' ? 'px-4' : 'px-1',
            },
            link: '/tender/setting/subcategory',
        },
        {
            item: {
                key: 'company',
                icon:
                    <GoOrganization
                        className={ClassNames('', {
                            'text-[#FBA22D]': currentItem === 'company',
                            'text-gray-500': currentItem !== 'company',
                        })}
                    />
                ,
                label: (<p className="text-sm font-semibold text-gray-900">Company</p>),
                className: currentItem === 'company' ? 'px-4' : 'px-1',
            },

            link: '/tender/setting/company',
        }
        // More menu items ...
    ]);

    console.log(menuItems.onlyItems.map(item => item.key));

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        const lastKey = pathSegments[pathSegments.length - 1];
        let mappedKey = '';
        switch (lastKey) {
            case 'language':
                mappedKey = 'language';
                break;
            case 'region':
                mappedKey = 'region';
                break;
            case 'source':
                mappedKey = 'source';
                break;
            case 'category':
                mappedKey = 'category';
                break;
            case 'subcategory':
                mappedKey = 'subcategory';
                break;
            case 'company':
                mappedKey = 'company';
                break;
            // Add more cases if there are additional menu items
            default:
                mappedKey = ''; // Optional: handle unrecognized paths
        }
        setCurrentItem(mappedKey);
    }, [location]);

    const onMenuClick = (e) => {
        const key = e.key;
        navigate(menuItems.findItem(key).link);
        const item = menuItems.findItem(key);
        if (item) {
            navigate(item.link); // Navigate to the link of the clicked item
        }
    };

    return (
        <div className="w-auto h-auto pb-6 pl-3 pr-6">
            <PageHeader
                title="Settings"
                description="Settings your Leave here"
            />
            <div className="flex gap-6 mt-8">
                <ConfigProvider
                    theme={{
                        components: {
                            Menu: {
                                itemHeight: 56,
                                itemPaddingInline: 0,
                                itemMarginInline: 0,
                                itemMarginBlock: 16,
                                itemActiveBg: '#F8F8F8',
                                itemHoverBg: 'rgba(248,248,248,0.92)',
                            },
                        },
                    }}
                >
                    <Menu
                        className="w-[300px] rounded-2xl py-2 px-6 h-max text-black"
                        items={menuItems.onlyItems}
                        mode="inline"
                        selectedKeys={[currentItem]}
                        onClick={onMenuClick}
                    />
                </ConfigProvider>
                <BlockWrapper className="flex-1 h-max"><Outlet /></BlockWrapper>
            </div>
        </div>
    )
}

export default TenderSettingsLayout