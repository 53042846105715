import { create } from 'zustand';


const regionSettingsSlice = (set) => ({
    isShowRegionSidebar: false,
    regionId: null,

    setIsShowRegionSidebar: (isShow) => {
        set({ isShowRegionSidebar: isShow });
    },
    setRegionId: (id) => {
        set({ regionId: id });
    },
});

export const useRegionSettingsStore = create(regionSettingsSlice);