import React, { useEffect } from 'react'
import CustomDrawerLayout from '../../../../components/CustomDrawerLayout/CustomDrawerLayout';
import { Button, Col, DatePicker, Form, Input, message, Select } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSourceSettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore';
import { crudRequest } from '../../../../utils/crudRequest';
import dayjs from 'dayjs';
import { BACKEND_URL } from '../../../../utils/constants';



const createSource = async (sourceData, sourceId = null) => {
    const url = sourceId ? `${BACKEND_URL}/v1/sources/${sourceId}` : `${BACKEND_URL}/v1/sources`;
    const method = sourceId ? 'PATCH' : 'POST';

    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(sourceData),
    });

    if (!response.ok) {
        throw new Error(`Failed to ${sourceId ? 'update' : 'create'} region`);
    }

    return response.json();
};


export const useDeleteSource = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteSource, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('sources');
            message.success("Successfully Delete");
        },
    });
};

const deleteSource = async (id) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/sources/${id}`,
        method: 'DELETE',
        data: { id },
    });
};

export const useSetSource = () => {
    const queryClient = useQueryClient();
    return useMutation(setSource, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('sources');
            message.success("Successfully Updated");
        },
    });
};


const setSource = async (id, items) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/sources/${id}`,
        method: 'PATCH',
        data: { items },
    });
};

const getSources = async (data) => {
    try {
        const response = await fetch(`${BACKEND_URL}/v1/sources`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await response.json();  // Adjust based on your API response structure
    } catch (error) {
        console.error('Error fetching sources:', error);
        return []; // Return an empty array in case of an error
    }
}
export const useGetSource = (data, isKeepData = true, isEnabled = true) => {
    return useQuery(
        data ? ['sources', data] : 'sources',
        () => getSources(data),
        {
            keepPreviousData: isKeepData,
            enabled: isEnabled,
        }
    );
};
const { Option } = Select;


function CreateSourceSidebar() {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();

    const {
        sourceId,
        setSourceId,
        isShowSourceSidebar: isShow,
        setIsShowSourceSidebar: setIsShow,
    } = useSourceSettingsStore();

    const { data, isFetching, refetch } = useGetSource(
        sourceId ? { filter: { id: [sourceId] } } : {},
        false,
        !!sourceId
    );
    useEffect(() => {
        if (sourceId) {
            refetch();
        }
    }, [sourceId]);

    const { mutate: setSource, isLoading } = useSetSource();

    const onClose = () => {
        form.resetFields();
        setSourceId(null);
        setIsShow(false);
    };

    const onFinish = () => {
        const values = form.getFieldValue();
        createSource(values, sourceId)
            .then(() => {
                message.success(`Source ${sourceId ? 'updated' : 'created'} successfully`);
                queryClient.invalidateQueries('sources'); // Refresh source data
                onClose();
            })
            .catch((error) => {
                console.error('Error saving source:', error);
            });
    }

    useEffect(() => {
        const fetchSourceData = async () => {
            if (sourceId) {
                try {
                    const response = await fetch(`${BACKEND_URL}/v1/sources/${sourceId}`);
                    if (!response.ok) {
                        throw new Error(`Failed to fetch region data for ID: ${sourceId}`);
                    }
                    const item = await response.json();
                    // Set form values correctly
                    form.setFieldsValue({ publisher: item.publisher });
                    form.setFieldsValue({
                        publishedAt: item.publishedAt ? dayjs(item.publishedAt) : null
                    });
                    form.setFieldsValue({ type: item.type });
                } catch (error) {
                    console.error("Error fetching region data:", error);
                }
            }
        };
        fetchSourceData();
    }, [sourceId]);

    return (
        isShow && (
            <CustomDrawerLayout
                open={isShow}
                onClose={onClose}
                modalHeader="Create Source"
                className="h-full "
                footer={
                    <div className="flex justify-center gap-2">
                        <Button size='large' onClick={onClose}>Cancel</Button>
                        <Button htmlType="submit" size='large' type="primary" loading={isLoading} onClick={() => form.submit()}>Create</Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    name="dependencies"
                    autoComplete="off"
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Col>
                        <Col>
                            <Form.Item
                                name="publisher"
                                label="Publisher"
                                rules={[
                                    { required: true, message: 'Enter Publisher' }
                                ]}
                            >
                                <Input placeholder="Enter Publisher" />
                            </Form.Item>
                        </Col>
                        <Form.Item
                            name="publishedAt"
                            label="Published Date"
                            rules={[
                                { required: true, message: 'Enter Published Date' }
                            ]} >
                            <DatePicker className="w-full" />
                        </Form.Item>
                        <Col >
                            <Form.Item
                                name="type"
                                label="Type"
                                rules={[
                                    { required: true, message: 'Select Type' },
                                ]}
                            >
                                <Select placeholder="Select Type">
                                    <Option value="Other">Private</Option>
                                    <Option value="Newspaper">News Paper</Option>
                                    <Option value="Government">Government</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Col>
                </Form>
            </CustomDrawerLayout >
        )
    )
}

export default CreateSourceSidebar