import { Button, Drawer } from 'antd'
import React, { useEffect } from 'react'
import { FaAngleRight } from 'react-icons/fa6'

function CustomDrawerLayout({
    open,
    onClose,
    modalHeader,
    children,
    width,
    hideButton = false,
    footer = null,
}) {


    return (
        <div>

            <Drawer
                title={modalHeader}
                width={width ? width : (window.innerWidth <= 768 ? '90%' : '30%')}
                closable={false}
                onClose={onClose}
                open={open}
                style={{ paddingBottom: 0, display: 'flex' }}
                footer={footer}
            >
                {children}
            </Drawer>

        </div>
    )
}

export default CustomDrawerLayout