import { Input, Select } from 'antd'
import { Option } from 'antd/es/mentions'
import React from 'react'

function AdminSearch() {
    return (
        <div className="flex flex-col items-center w-full pb-4 space-y-4">
            <div className="flex flex-col w-full sm:flex-row sm:flex-wrap">
                <div className="w-full p-2 sm:w-1/2 lg:w-1/3">
                    <Input
                        id="searchEmployee"
                        placeholder='Search Admin'
                        onChange={() => { }}
                        className="w-full h-14"
                    />
                </div>
                <div className="w-full p-2 sm:w-1/2 lg:w-1/3">
                    <Select
                        id="searchByRoleListData"
                        size="large"
                        placeholder="User Type"
                        onChange={() => { }}
                        className="w-full h-14"
                        allowClear
                    >

                    </Select>
                </div>
                <div className="w-full p-2 sm:w-1/2 lg:w-1/3">
                    <Select
                        id="searchByAccountStatus"
                        placeholder="Account Status"
                        onChange={() => { }}
                        className="w-full h-14"
                    >
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Select>
                </div>
            </div>
        </div>
    )
}

export default AdminSearch