import { Button, Col, Form, Input, message, Select } from 'antd';
import React, { useEffect } from 'react'
import CustomDrawerLayout from '../../../../components/CustomDrawerLayout/CustomDrawerLayout';
import { useSubCategorySettingsStore } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { crudRequest } from '../../../../utils/crudRequest';
import { BACKEND_URL } from '../../../../utils/constants';

const { Option } = Select;

const createSubCategory = async (subCategoryData, subCategoryId = null) => {
    const url = subCategoryId ? `${BACKEND_URL}/v1/tender-sub-category/${subCategoryId}` : `${BACKEND_URL}/v1/tender-sub-category`;
    const method = subCategoryId ? 'PATCH' : 'POST';

    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(subCategoryData),
    });

    if (!response.ok) {
        throw new Error(`Failed to ${subCategoryId ? 'update' : 'create'} tender sub category`);
    }

    return response.json();
};

export const useDeleteSubCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteSubCategory, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('tender-sub-category');
            message.success("Successfully Delete");
        },
    });
};
const deleteSubCategory = async (id) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/tender-sub-category/${id}`,
        method: 'DELETE',
        data: { id },
    });
};

export const useSetSubCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(setSubCategory, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('tender-sub-category');
            message.success("Successfully Updated");
        },
    });
};

const setSubCategory = async (id, items) => {
    return await crudRequest({
        url: `${BACKEND_URL}/v1/tender-sub-category/${id}`,
        method: 'PATCH',
        data: { items },
    });
};

const getSubCategory = async (data) => {
    try {
        const response = await fetch(`${BACKEND_URL}/v1/tender-sub-category`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await response.json();  // Adjust based on your API response structure
    } catch (error) {
        console.error('Error fetching Tender Category:', error);
        return []; // Return an empty array in case of an error
    }
}
export const useGetSubCategory = (data, isKeepData = true, isEnabled = true) => {
    return useQuery(
        data ? ['tender-sub-category', data] : 'tender-sub-category',
        () => getSubCategory(data),
        {
            keepPreviousData: isKeepData,
            enabled: isEnabled,
        }
    );
};


const getCategory = async () => {
    const response = await fetch(`${BACKEND_URL}/v1/tender-category`, { method: 'GET' });
    return await response.json();
};





function SubCreateCategorySidebar() {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const {
        subCategoryId,
        setSubCategoryId,
        isShowSubCategorySidebar: isShow,
        setIsShowSubCategorySidebar: setIsShow,
    } = useSubCategorySettingsStore();

    const { data, isFetching, refetch } = useGetSubCategory(
        subCategoryId ? { filter: { id: [subCategoryId] } } : {},
        false,
        !!subCategoryId
    );
    const { mutate: setSubCategory, isLoading } = useGetSubCategory();
    const { isLoading: isCategoryLoading, data: CategoryData } = useQuery('tender_category', getCategory);


    useEffect(() => {
        if (subCategoryId) {
            refetch();
        }
    }, [subCategoryId]);



    const onFinish = () => {
        const values = form.getFieldValue();
        createSubCategory(values, subCategoryId)
            .then(() => {
                message.success(`Sub Category ${subCategoryId ? 'updated' : 'created'} successfully`);
                queryClient.invalidateQueries('tender-sub-category');
                onClose();
            })
            .catch((error) => {
                console.error('Error saving Sub Category:', error);
            });
    }
    const onClose = () => {
        form.resetFields();
        setSubCategoryId(null);
        setIsShow(false);
    };


    useEffect(() => {
        const fetchCategoryData = async () => {
            if (subCategoryId) {
                fetch(`${BACKEND_URL}/v1/tender-sub-category/${subCategoryId}`)
                    .then(res => res.json())
                    .then(
                        data => {
                            form.setFieldsValue(data)
                            form.setFieldsValue({ parentCategoryId: data.parentCategory.name });
                        })
                    .catch(error => console.error("Error fetching Company data:", error));
            }
        };
        fetchCategoryData();
    }, [subCategoryId, form]);

    return (
        isShow && (
            <CustomDrawerLayout
                open={isShow}
                onClose={onClose}
                modalHeader="Create SubCategory"
                className="h-full "
                footer={
                    <div className="flex justify-center gap-2">
                        <Button size='large' onClick={onClose}>Cancel</Button>
                        <Button htmlType="submit" size='large' type="primary" onClick={() => form.submit()}>{subCategoryId ? "Update" : "Create"}</Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    autoComplete="off"
                    onFinish={onFinish}
                    layout="vertical"
                >

                    <Form.Item
                        name="name"
                        label="Sub Category Name"
                        rules={[
                            { required: true, message: 'Enter Sub Category name!' }
                        ]}
                    >
                        <Input placeholder="Enter Category" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Sub Category Description"
                        rules={[
                            { required: true, message: 'Enter Sub Category Description!' }
                        ]}
                    >
                        <Input placeholder="Enter Sub Category description" />
                    </Form.Item>
                    {subCategoryId ?
                        <>
                            <Form.Item name="parentCategoryId" label="Parent Category" rules={[{ required: true, message: 'Select Company Sector!' }]}>
                                <Select mode="multiple" maxCount={1} placeholder="Select sectors" loading={isLoading}>
                                    {CategoryData?.map((category) => (
                                        <Option key={category.id} value={category.id}>{category.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </>
                        :
                        <Form.Item name="parentCategoryId" label="Parent Category" rules={[{ required: true, message: 'Select Company Sector!' }]}>
                            <Select placeholder="Select sectors" loading={isLoading}>
                                {CategoryData?.map((category) => (
                                    <Option key={category.id} value={category.id}>{category.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }
                </Form>

            </CustomDrawerLayout>
        )
    )
}

export default SubCreateCategorySidebar