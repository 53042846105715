import React, { useEffect } from 'react'
import AdminSettingsLayout from './Layout/AdminSettingsLayout'
import { useNavigate } from 'react-router-dom';

function AdminSettings() {

    const navigate = useNavigate();

    useEffect(() => {
        navigate("/admins/setting/closed-dates");
    })
    return (
        <>

        </>
    )
}

export default AdminSettings