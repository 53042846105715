import { message, Spin } from 'antd';
import React from 'react'
import ActionsButtons from '../../../../../components/Buttons/ActionButton/ActionsButtons';
import { BACKEND_URL } from '../../../../../utils/constants';
import { crudRequest } from '../../../../../utils/crudRequest';
import { useMutation, useQueryClient } from 'react-query';
import { useRegionSettingsStore } from '../../../../../stores/Server/features/Tender-Management/Setting/regionSettingsStore';


export const useDeleteRegion = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteRegion, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('regions');
            message.success("Successfully Delete");
        },
    });
};
const deleteRegion = async (id) => {
    return await crudRequest({
        url: `${BACKEND_URL}/api/v1/regions/${id}`,
        method: 'DELETE',
        data: { id },
    });
};

export const useSetRegion = () => {
    const queryClient = useQueryClient();
    return useMutation(setRegion, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries('regions');
            message.success("Successfully Updated");
        },
    });
};


const setRegion = async (id, items) => {
    return await crudRequest({
        url: `${BACKEND_URL}/api/v1/regions/${id}`,
        method: 'PUT',
        data: { items },
    });
};



function ListingCard({ item }) {
    const { setRegionId, setIsShowRegionSidebar } =
        useRegionSettingsStore();
    const { mutate: deleteRegion, isLoading } = useDeleteRegion();

    return (
        <Spin spinning={isLoading}>
            <div className='w-[600px]'>
                <div className="flex flex-row justify-between items-center p-6 rounded-2xl border border-gray-200 mt-6 gap-2.5">
                    <div className="flex-1 text-lg font-semibold text-gray-900">
                        {item?.name}
                    </div>

                    <ActionsButtons
                        id={item?.id ?? null}
                        onDelete={() => {
                            deleteRegion([item.id]);
                        }}
                        onEdit={() => {
                            setRegionId(item.id);
                            setIsShowRegionSidebar(true);
                        }}
                    />
                </div>
            </div>
        </Spin>
    );
}

export default ListingCard