import { Button, Result, Empty } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';


function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="grid min-h-screen place-items-center">
            <Result
                icon={<Empty size="large" alt="404" />}
                title="Page Not Found"
                subTitle={<p className='text-lg font-medium text-black'>404</p>}
                extra={
                    <Button type="primary" size='large' onClick={() => navigate("/")}>
                        <p className='px-5 py-10 text-xl'>Go Back Home</p>
                    </Button>
                }
            />

        </div>
    )
}

export default NotFound