import React, { useEffect, useState } from 'react'
import PageHeader from '../../../ClientManagement/Settings/Header/PageHeader'
import { Button, Divider, Spin } from 'antd'
import { LuPlus } from 'react-icons/lu'
import CreateLanguageModal, { useDeleteLanuage, useGetLanguage } from '../Modal/CreateLanguageSidebar';
import ActionsButtons from '../../../../components/Buttons/ActionButton/ActionsButtons';
import { useQuery } from 'react-query';
import { useLanguageSettingsStore, } from '../../../../stores/Server/features/Tender-Management/Setting/tenderSettingStore';
import CreateLanguageSidebar from '../Modal/CreateLanguageSidebar';
import ListingCard from '../Components/ListingCard/ListingCard';






function Language() {
    const { languageId, setLanguageId, isShowLanguageSidebar, setIsShowLanguageSidebar } = useLanguageSettingsStore();
    const { isFetching, data, refetch } = useGetLanguage();
    const { mutate: deleteLanguage, isLoading } = useDeleteLanuage();


    useEffect(() => {
        if (!isShowLanguageSidebar) {
            refetch();
        }
    }, [isShowLanguageSidebar]);



    return (
        <div className='flex flex-col justify-center w-full'>
            <PageHeader title="Language" size="small">
                <Button
                    size="large"
                    type="primary"
                    icon={<LuPlus size={18} />}
                    onClick={() => { setIsShowLanguageSidebar(true) }}
                >
                    Add Language
                </Button>
            </PageHeader>
            <Divider className='' />
            <Spin spinning={isFetching}>
                <div className='flex flex-col items-center justify-center w-full gap-2'>
                    {
                        data?.map((language) => (
                            <Spin spinning={isLoading} >
                                <div className='w-[600px]' key={language.id}>
                                    <div className="flex flex-row justify-between items-center p-6 rounded-2xl border border-gray-200 mt-6 gap-2.5">
                                        <div className="flex-1 text-lg font-semibold text-gray-900">
                                            {language?.name}
                                        </div>

                                        <ActionsButtons
                                            id={language?.id ?? null}
                                            onDelete={() => {
                                                deleteLanguage([language.id]);
                                            }}
                                            onEdit={() => {
                                                console.log(language.id)
                                                setLanguageId(language.id);
                                                setIsShowLanguageSidebar(true);
                                            }}
                                        />
                                    </div>
                                </div>

                            </Spin>
                        ))
                    }
                </div>
            </Spin>
            <CreateLanguageSidebar />
        </div>
    )
}

export default Language