import React, { useState } from 'react';
import CustomBreadCrumb from '../../../components/CustomBreadCrumb/CustomBreadCrumb';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { FaPlus } from "react-icons/fa6";
import UserSideBar from './Components/Modal/UserSideBar';
import AdminTable from './Components/Table/AdminTable';
import EmployeeSearch from './Components/Search/AdminSearch';
import AdminSearch from './Components/Search/AdminSearch';

function Admins() {
    const [open, setModal] = useState(false); // State to manage sidebar visibility

    const showDrawer = () => {
        console.log("Drawer", true);
        setModal(true); // Open the sidebar
    };

    const closeDrawer = () => {
        setModal(false); // Close the sidebar
    };

    return (
        <div className='w-auto h-auto px-5 bg-white rounded-lg'>
            <div className='flex flex-wrap items-center justify-between'>
                <CustomBreadCrumb title="Admin" subtitle="Manage your Admin" />
                <CustomButton
                    title="Create Admin"
                    id="createAdminButton"
                    onClick={showDrawer} // Open sidebar on button click
                    className="bg-blue-600 hover:bg-blue-700"
                    icon={<FaPlus className="mr-2" />}
                />

                {/* Passs open state and onClose handler to UserSideBar */}
                {open && (
                    <UserSideBar onClose={closeDrawer} open={open} />
                )}
            </div>
            <div className="flex flex-col items-center justify-center w-full h-auto">
                <AdminSearch />
                <AdminTable />
            </div>
        </div>
    );
}

export default Admins;
